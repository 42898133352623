import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import CostInsight from "./CostInsight";
import TagAllocation from "./TagAllocation";
import RI_SP from "./RI_SP";
import DataTransfer from "./DataTransfer";

import InaccessibleResources from "../FreeTrial/InaccessibleResources";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

// Main Cost Optimization Dashboard
const CostInsightDashboard = ({ licenseName, message }) => {
  const [activeTab, setActiveTab] = useState("cost-insight");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "cost-insight":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          // Cost Insight
          return <GetKibanaDashboard dashboardKey={'cur_2_dashboard'} />;
        }
      case "tags":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          // TagAllocation
          return <GetKibanaDashboard dashboardKey={'CUR_2_insight_Allocation'} />;
        }
      case "ri_sp":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          // RI_SP
          return <GetKibanaDashboard dashboardKey={'RI/SP Dashboard'} />;
        }
      case "dataTransfer":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          // DataTransfer
          return <GetKibanaDashboard dashboardKey={'Data Transfer'} />;
        }
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="COST INSIGHT" value="cost-insight" />
        <Tab label="TAG ALLOCATION" value="tags" />
        <Tab label="RI/SP" value="ri_sp" />
        <Tab label="Data Transfer" value="dataTransfer" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default CostInsightDashboard;
