import React from "react";
import styles from "./landingpage.module.css";
import CardComponent from "./CardComponent";
import { Alert } from "@mui/material";
import { useHasActiveUserAccounts, useIsCostOptimizationEnabledForOneUserAccount } from "../../../components/Common/HelperComponents/CustomHooks";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const CostOptimizationCard = ({ licenseName, monthlySavings, accounts }) => {
  const isPaidLicense = !freeLicenses.includes(licenseName);
  const hasActiveAccounts = useHasActiveUserAccounts(); // Check if user has active accounts
  const isCostOptimizationEnabled = useIsCostOptimizationEnabledForOneUserAccount(accounts); // Check if Cost Optimization is enabled

  return (
    <div className={styles.costcard}>
      <div className={styles.cardheader}>
        <div className={styles.headertext} title="Cost Optimization">
          Cost Optimization
        </div>
      </div>
      {isPaidLicense ? (
        <>
          <div className={`${styles.fullHeightCard}`}>
            {hasActiveAccounts ? (
              isCostOptimizationEnabled ? (
                <CardComponent
                  title="Monthly Savings Opportunity"
                  amount={monthlySavings}
                />
              ) : (
                <div className={styles.card}>
                  <Alert variant="outlined" severity="info">
                    Cost Optimization is currently disabled. Enable this feature
                    to view Estimated Monthly Savings and actionable insights.
                  </Alert>
                </div>
              )
            ) : (
              <Alert variant="outlined" severity="warning">
                Please make sure you have access to at least one active account.
              </Alert>
            )}
          </div>
        </>
      ) : (
        <Alert variant="outlined" severity="info">
          Please subscribe to unlock this dashboard and gain full access to your
          cloud environment’s cost insight!
        </Alert>
      )}
    </div>
  );
};

export default CostOptimizationCard;
