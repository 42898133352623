import React, { useMemo } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { useAccessibleUserAccounts } from "../../../components/Common/HelperComponents/CustomHooks";
import { useOrganizations } from "../../../components/Common/HelperComponents/GetDataFromStore";
import { useCostInsightData } from "./useCostInsightData"; // Custom hook for fetching data
import ApiLoading from "../components/ApiLoading";
import ApiError from "../components/ApiError";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const CostInsight = () => {
  const theme = useTheme();
  const history = useHistory();
  const accessibleAccounts = useAccessibleUserAccounts();
  const organizations = useOrganizations();

  const accCodes = useMemo(
    () => accessibleAccounts.map((account) => account.accountCode),
    [accessibleAccounts]
  );
  const orgCode = useMemo(() => organizations?.[0]?.orgcode, [organizations]);

  const { data, loading, error } = useCostInsightData(accCodes, orgCode);

  const dollarColor = "#228B22"; // US dollar green

  const handleGoTo = () => {
    console.log("Go-to action clicked");
    history.push("/costinsight");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        padding: "8px 12px",
        height: "auto", // Let height adjust automatically
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header with Icons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: 1,
          paddingBottom: "2px",
          borderBottom: `1px solid`,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", color: "text.primary" }}
        >
          Cost Insight
        </Typography>
        <Box display="flex" alignItems="center">
          <Tooltip title="Open Detailed View">
            <IconButton
              aria-label="go-to"
              sx={{ color: "text.secondary", fontSize: "20px" }}
              onClick={handleGoTo}
            >
              <OpenInNewOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ alignSelf: "center", height: "18px" }}
          />
          <IconButton
            aria-label="settings"
            className="drag-handle"
            sx={{ color: "text.secondary", fontSize: "20px" }}
          >
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {/* Content Layout */}
      {loading ? (
        <ApiLoading parameter="Cost Insight" />
      ) : error ? (
        <ApiError parameter="Cost Insight" />
      ) : (
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Monthly Savings Opportunity
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: dollarColor, fontWeight: "bold" }}
            >
              ${data?.costInsightMonthlySavingsCost || "0.00"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Yesterday's Spend
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: dollarColor, fontWeight: "bold" }}
            >
              ${data?.costInsightYesterdayCost || "0.00"}
            </Typography>
          </Grid>

          {/* Second Row */}
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Month To Date Spend
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: dollarColor, fontWeight: "bold" }}
            >
              ${data?.costInsightMonthToDateCost || "0.00"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Year To Date Spend
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: dollarColor, fontWeight: "bold" }}
            >
              ${data?.costInsightYearToDateCost || "0.00"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CostInsight;
