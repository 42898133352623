import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import DynamoDB from "./DynamoDB";
import DaxCluster from "./DaxCluster";

const DynamoDBDashboard = () => {
    const [activeTab, setActiveTab] = useState("DynamoDB");
  
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };
  
    const renderTabContent = () => {
      switch (activeTab) {
        case "DynamoDB":
          return <DynamoDB />;
        case "DaxCluster":
          return <DaxCluster />;
        default:
          return null;
      }
    };
  
    return (
      <div style={{"marginTop": "5rem"}}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="DynamoDB" value="DynamoDB" />
          <Tab label="Dax Cluster " value="DaxCluster" />
        </Tabs>
        {renderTabContent()}
      </div>
    );
  };
  
  export default DynamoDBDashboard;