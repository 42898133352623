import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

export const BackupJobs = () => {
  const VIEW_URL = process.env.REACT_APP_BACKUP_JOBS_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const BackupPlan = () => {
  const VIEW_URL = process.env.REACT_APP_BACKUP_PLAN_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const BackupVault = () => {
  const VIEW_URL = process.env.REACT_APP_BACKUP_VAULT_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const Framework = () => {
  const VIEW_URL = process.env.REACT_APP_FRAMEWORK_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const LegalHold = () => {
  const VIEW_URL = process.env.REACT_APP_LEGAL_HOLD_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const ProtectedResources = () => {
  const VIEW_URL = process.env.REACT_APP_PROTECTED_RESOURCES_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const Reports = () => {
  const VIEW_URL = process.env.REACT_APP_REPORTS_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};
