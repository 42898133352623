import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

const EC2 = () => {
  const VIEW_URL = process.env.REACT_APP_EC2_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export default EC2;
