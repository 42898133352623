import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iframe from "./iframe";
import { getTabToken, clearTabToken } from "../../store/actions";
import { getListOfAccts } from "./../../store/actions";
import * as Loader from "react-loader-spinner";
import jwt_decode from 'jwt-decode';

const EmbedShell = ({ viewUrl }) => {
  const dispatch = useDispatch();

  const {
    accounts,
    organizations,
    loading: accountsLoading,
    error: accountsError,
  } = useSelector((state) => {
    const { accounts, organizations, loading, error } = state.acctList;
    return {
      accounts,
      organizations:
        organizations && organizations.length > 0 ? organizations[0] : {},
      loading,
      error,
    };
  });

  const stateTab = useSelector((state) => state.tableauList);
  const { tabToken, error: tabTokenError, loading: tabTokenLoading } = stateTab;

  const accountIds = "&accCode=";
  const organizationId = "&orgCode=";
  var accountCodesList = null;
  var orgCodes = null;
  const from = "&fromDt=";
  const to = "&toDt=";
  const tabTrusted = "trusted/";
  const fromDt = new Date();
  fromDt.setDate(fromDt.getDate() - 2);
  const toDt = new Date();
  const [src, setSrc] = useState("");
  const tabBase = process.env.REACT_APP_TABLEAU_BASE;


  let username;
  if(localStorage.getItem("authUser"))
  {
       const obj = JSON.parse(localStorage.getItem("authUser"));
       const authUser = jwt_decode(obj.idToken);
       username = authUser.email;
  }

  //retrieve accIds and tabToken on initial load
  useEffect(() => {
    // Fetch new token on Url change
    console.log("Getting new tab token");
    dispatch(getTabToken());

    if (!accounts || !organizations) {
      dispatch(getListOfAccts());
    }
  }, [viewUrl]);

  //filter accounts and update url in response to account number change
  useEffect(() => {
    if (tabToken !== -1) {
      const filtered =
        accounts &&
        accounts.filter(
          (x) =>
            !x.accountNumber.includes("INIT00000001") &&
            !x.accountNumber.includes("TEST") &&
            x.accountNumber.length == 12
        );
      console.log("Filetered :: ", filtered);
      const accountsLi = [];
      const orgLi = [];
      console.log("organizations,", organizations);
      filtered &&
        filtered.map((acct) => {
          if (acct.status === 'ACTIVE' && acct.listusr?.some(user => user.userName === username))
            {accountsLi.push(`${acct.accountCode}`)};
        });
      console.log("Acct Code  :: ", accountsLi);
      accountCodesList = accountsLi.toString();
      console.log("Acct Code List :: ", accountCodesList);
      console.log("Organizations :: ", organizations.orgcode);
      orgLi.push(organizations.orgcode);
      orgCodes = orgLi.toString();
      console.log("Org Code :: ", orgCodes);
      console.log("tabToken :: ", tabToken);
      if (
        orgCodes.length > 0 &&
        accountCodesList.length > 0 &&
        tabToken !== null &&
        tabToken !== -1 &&
        tabToken !== null
      ) {
        getUrl();
      }
    }
  }, [tabToken, accounts, organizations]);

  //update url w/accountIds and tabToken
  const getUrl = () => {
    if (tabToken != null) {
      // https://alb01.dev01.cntdev.link/views/AssetManagement_EC2_L1_16432208718170/EC2Summary?:origin=viz_share_link&:display_count=n&:showVizHome=n
      var tabUrl =
        tabBase +
        tabTrusted +
        "TKNPLCHOLDER" +
        viewUrl +
        from +
        fromDt.toISOString() +
        to +
        toDt.toISOString() +
        accountIds +
        accountCodesList +
        organizationId +
        orgCodes;
      var taburl2 = tabUrl.replace("TKNPLCHOLDER", tabToken);
      console.log("ctl url", taburl2);
      setSrc(taburl2);
      // Since we have used token, now clear it from redux store.
      dispatch(clearTabToken());
    }
  };
  // if call has returned then show loading
  if (tabTokenError || accountsError) {
    console.log("token1", tabToken);
    return (
      <div className="box" id="tableauDivErr">
        <h5>We're having some difficulty reaching our servers!</h5>
        <h5>
          If this problem persists, please contact
          Support.CloudCatcher@cloudnovatech.com
        </h5>
      </div>
    );
  } else {
    console.log("token3", tabToken);
    if (src.length === 0) {
      console.log("Displaying rings...");
      return (
        <div className="tableauDivLoading" id="tableauDivLoading">
          <Loader.Rings color="#00BFFF" height={200} width={200} />
        </div>
      );
    } else {
      console.log("Displaying UI");
      return (
        <div className="box" id="tableauDiv">
          <Iframe source={src} />
        </div>
      );
    }
  }
};

export default EmbedShell;
