import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import AssetManagementInfoCard from "./components/AssetManagementInfoCard";
import SecurityScoreCard from "./components/SecurityScoreCard";
import ComplianceInfoCard from "./components/ComplianceInfoCard";
import styles from "./components/landingpage.module.css";

import ApiError from "./components/ApiError";
import ApiLoading from "./components/ApiLoading";

import accessToken from "../../helpers/jwt-token-access/accessToken";
import jwt_decode from "jwt-decode";

import { connect, useSelector } from "react-redux";
import {
  getListOfOrgsAndAccts,
  getAssetInsightMetric,
  getComplianceMetric,
  getCostInsightMetric,
  getSecurityPostureMetric,
  getLicensingData,
} from "./../../store/actions";

import {
  apiDataToAssetInsightMetricObject,
  apiDataToCostInsightMetricObject,
  apiDataToSecurityPostureMetricObject,
  apiDataToDynamicComplianceMetricObject,
} from "./apiResponseToMetricData";
import EventInsightInforCard from "./components/EventInsightInfoCard";
import CostInsightCard from "./components/CostInsightCard";
import ErrorBoundary from "../../components/ErrorBoundary";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";

import { Alert, Snackbar } from "@mui/material";

import loadingGif from "./../../assets/images/aws/loadingGif.gif";
import SubscribeModal from "../Subscribe";
import CostOptimizationCard from "./components/CostOptimizationCard";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];
// const freeLicenses = ["TrialLinkedLicense"];

let getListOfOrgsAndAcctsFun;
// Store data fetch date and time as string, shown below.
// let dataFetchDate = new Date().toLocaleString();
let dataFetchDate;

const LandingPage = (props) => {
  const [apiDataTime, setApiDataTime] = useState(null);

  const [assetInsightMetricObject, setAssetInsightMetricObject] =
    useState(null);
  const [assetInsightLoading, setAssetInsightLoading] = useState(false);
  const [assetInsightApiError, setAssetInsightApiError] = useState(null);

  const [complianceMetricObject, setComplianceMetricObject] = useState(null);
  const [complianceLoading, setComplianceLoading] = useState(false);
  const [complianceApiError, setComplianceApiError] = useState(null);

  const [costInsightMetricObject, setCostInsightMetricObject] = useState(null);
  const [costInsightLoading, setCostInsightLoading] = useState(false);
  const [costInsightApiError, setCostInsightApiError] = useState(null);

  const [securityPostureMetricObject, setSecurityPostureMetricObject] =
    useState(null);
  const [securityPostureLoading, setSecurityPostureLoading] = useState(false);
  const [securityPostureApiError, setSecurityPostureApiError] = useState(null);

  // Disable Refresh Button for 10 minutes
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAccountConfigured, setIsAccountConfigured] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);

  const [configInfoOpen, setConfigInfoOpen] = useState(false);
  const profile = useSelector((state) => state.profile);

  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [contactAdminOpen, setContactAdminOpen] = useState(false);

  // console.log("~~~Profile:", profile);

  let accCodes, orgCode;

  let lastSevenDays;
  let daysLeftMessage;

  let responseEl;
  let awsAccountConfigEl;
  let subscribeInfoBarEl;
  // for Individual
  let assetInsightResponseEl;
  let securityPostureResponseEl;
  let complianceResponseEl;
  let costInsightResponseEl;
  let costOptResponseEl;
  let eventDataResponseEl;
  // For event data, it is to be added

  const storeApiDataTimeInSessionStorage = () => {
    const currentTime = new Date().getTime();
    sessionStorage.setItem("apiDataTime", currentTime);
  };

  // Actual updateApiData method
  const updateApiData = async (ignore) => {
    const fetchAssetInsightData = async () => {
      props.getAssetInsightMetricInfo(accCodes, orgCode);
    };

    const fetchComplianceData = async () => {
      props.getComplianceMetricInfo(accCodes, orgCode);
    };

    const fetchSecurityPostureData = async () => {
      props.getSecurityPostureMetricInfo(accCodes, orgCode);
    };

    const fetchCostInsightData = async () => {
      props.getCostInsightMetricInfo(accCodes, orgCode);
    };

    if (accCodes && orgCode) {
      responseEl = null;
      dataFetchDate = new Date().toLocaleString();
      fetchAssetInsightData();
      fetchComplianceData();
      fetchSecurityPostureData();
      fetchCostInsightData();
    }
  };

  getListOfOrgsAndAcctsFun = props.getListOfOrgsAndAccts;
  const getLicensingData = props.getLicensingData;
  const currentLicense = props.licenseData?.data?.licenseName;
  const currentLicenseLoading = props.licenseData?.loading;
  const currentOrgs = props.organizations;
  useEffect(() => {
    if (!currentOrgs) {
      getListOfOrgsAndAcctsFun();
    }
    if (!currentLicense && !currentLicenseLoading) {
      getLicensingData();
    }
  }, [currentOrgs, currentLicense, getLicensingData, currentLicenseLoading]);

  // Disabling Refresh Button once Clicked
  useEffect(() => {
    // Check local storage for the last click time
    const lastClickTime = localStorage.getItem("lastClickTime");
    if (lastClickTime) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(lastClickTime, 10);
      if (timeDifference < 10 * 60 * 1000) {
        // If less than 10 minutes, disable the button
        setIsButtonDisabled(true);
        // Schedule re-enabling after 10 minutes
        setTimeout(() => {
          setIsButtonDisabled(false);
          localStorage.removeItem("lastClickTime");
        }, 10 * 60 * 1000 - timeDifference);
      }
    }
  }, []);

  // useEffect for initial API data rendering
  useEffect(() => {
    let ignore = false;
    if (accCodes && orgCode) {
      updateApiData(ignore);
      //Update dataFetchDate for UI header
      dataFetchDate = new Date().toLocaleString();
    }
  }, [apiDataTime]);

  useEffect(() => {
    if (props.assetInsightMetric) {
      const metricObject = apiDataToAssetInsightMetricObject(
        props.assetInsightMetric
      );
      setAssetInsightMetricObject(metricObject);
      sessionStorage.setItem(
        "assetInsightMetricObject",
        JSON.stringify(metricObject)
      );
    }
    setAssetInsightLoading(props.assetInsightMetricLoading);
    setAssetInsightApiError(
      props.assetInsightMetricError ? "Error in Asset Insight API" : null
    );
  }, [
    props.assetInsightMetric,
    props.assetInsightMetricError,
    props.assetInsightMetricLoading,
  ]);

  useEffect(() => {
    if (props.costInsightMetric) {
      const metricObject = apiDataToCostInsightMetricObject(
        props.costInsightMetric
      );
      setCostInsightMetricObject(metricObject);
      sessionStorage.setItem(
        "costInsightMetricObject",
        JSON.stringify(metricObject)
      );
    }
    setCostInsightLoading(props.costInsightMetricLoading);
    setCostInsightApiError(
      props.costInsightMetricError ? "Error in Cost Insight API" : null
    );
  }, [
    props.costInsightMetric,
    props.costInsightMetricError,
    props.costInsightMetricLoading,
  ]);

  useEffect(() => {
    if (props.complianceMetric) {
      const metricObject = apiDataToDynamicComplianceMetricObject(
        props.complianceMetric
      );
      setComplianceMetricObject(metricObject);
      sessionStorage.setItem(
        "complianceMetricObject",
        JSON.stringify(metricObject)
      );
    }

    setComplianceLoading(props.complianceMetricLoading);
    setComplianceApiError(
      props.complianceMetricError ? "Error in Compliance API" : null
    );
  }, [
    props.complianceMetric,
    props.complianceMetricError,
    props.complianceMetricLoading,
  ]);

  useEffect(() => {
    if (props.securityPostureMetric) {
      const metricObject = apiDataToSecurityPostureMetricObject(
        props.securityPostureMetric
      );
      setSecurityPostureMetricObject(metricObject);
      sessionStorage.setItem(
        "securityPostureMetricObject",
        JSON.stringify(metricObject)
      );
    }
    setSecurityPostureLoading(props.securityPostureMetricLoading);
    setSecurityPostureApiError(
      props.securityPostureMetricError ? "Error in Security Posture API" : null
    );
  }, [
    props.securityPostureMetric,
    props.securityPostureMetricError,
    props.securityPostureMetricLoading,
  ]);

  // useEffect to display snackbar about configuration message
  useEffect(() => {
    if (currentLicense) {
      if (freeLicenses.includes(currentLicense)) {
        setConfigInfoOpen(true);
      }
    }
  }, [currentLicense]);

  const handleClick = (event) => {
    updateApiData(false);
    dataFetchDate = new Date().toLocaleString();
    //Store
    storeApiDataTimeInSessionStorage();
    getListOfOrgsAndAcctsFun();
    // Handle click logic here
    setIsButtonDisabled(true);

    // Store the current time in local storage
    const currentTime = new Date().getTime();
    localStorage.setItem("lastClickTime", currentTime);

    // Schedule re-enabling after 10 minutes
    setTimeout(() => {
      setIsButtonDisabled(false);
      localStorage.removeItem("lastClickTime");
    }, 10 * 60 * 1000);
  };

  let username, userInfo;
  let accountNumbers = [];
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const authUser = jwt_decode(obj.token);
    userInfo = jwt_decode(obj.idToken).email;
    username = authUser.sub.charAt(0) + authUser.sub.slice(1);
  }
  const token = accessToken();

  if (props.accounts) {
    // console.log("userInfo:", userInfo);
    // console.log("props.accounts: ", props.accounts);
    // console.log("accountNumbers: ", accountNumbers);
    accCodes = props.accounts
      .filter(
        (account) =>
          account.status === "ACTIVE" &&
          account.listusr?.some((user) => user.userName === userInfo)
      )
      .map((account) => account.accountCode);
    props.accounts.map((account) => accountNumbers.push(account.accountNumber));
    // console.log("accCodes: ", accCodes);
    if (accCodes.length > 0) {
      if (isAccountConfigured === null) {
        setIsAccountConfigured(true);
        setAccountStatus("ACTIVE");
      }
    } else if (isAccountConfigured === null) {
      setIsAccountConfigured(false);
      const inactiveAccounts = props.accounts.filter(
        (account) =>
          account.status === "INACTIVE" && account.number !== "INIT00000001"
      );
      if (inactiveAccounts.length > 0) {
        setAccountStatus("INACTIVE");
      } else {
        setAccountStatus("NOACCOUNT");
      }
    }
  }
  if (props.organizations) {
    orgCode = props.organizations[0].orgcode;
    //Added for loading data very first time.
    if (orgCode) {
      if (!apiDataTime) setApiDataTime(-1);
    }
  }

  //updateApiData(false);

  // Generating spinner UI while license data is loading.
  if (props?.licenseData?.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <img src={loadingGif} height={40} alt="Loading Spinner" />
      </div>
    );
  }

  //Generating UI element for asset insight
  if (assetInsightApiError) {
    assetInsightResponseEl = <ApiError parameter="Asset Insight" />;
  } else if (assetInsightLoading) {
    assetInsightResponseEl = <ApiLoading parameter="Asset Insight" />;
  } else if (assetInsightMetricObject) {
    assetInsightResponseEl = (
      <ErrorBoundary>
        <AssetManagementInfoCard metricObject={assetInsightMetricObject} />{" "}
      </ErrorBoundary>
    );
  }

  //Generating UI element for compliance
  if (complianceApiError) {
    complianceResponseEl = <ApiError parameter="Compliance" />;
  } else if (complianceLoading) {
    complianceResponseEl = <ApiLoading parameter="Compliance" />;
  } else if (complianceMetricObject) {
    complianceResponseEl = (
      <Link className="text-white rounded-5" to="/Compliance">
        <ErrorBoundary>
          <ComplianceInfoCard complianceData={complianceMetricObject} />
        </ErrorBoundary>
      </Link>
    );
  }

  //Generating UI element for cost insight
  if (costInsightApiError) {
    costInsightResponseEl = <ApiError parameter="Cost Insight" />;
  } else if (costInsightLoading) {
    costInsightResponseEl = <ApiLoading parameter="Cost Insight" />;
  } else if (costInsightMetricObject) {
    costInsightResponseEl = (
      <Link className="text-white rounded-5" to="/costinsight">
        <ErrorBoundary>
          <CostInsightCard
            licenseName={props.licenseData?.data?.licenseName}
            yesterdayCost={costInsightMetricObject.costInsightYesterdayCost}
            monthToDateCost={costInsightMetricObject.costInsightMonthToDateCost}
            yearToDateCost={costInsightMetricObject.costInsightYearToDateCost}
            accounts={props.accounts}
          />
        </ErrorBoundary>
      </Link>
    );
  }

  //Generating UI element for cost optimization
  if (costInsightApiError) {
    costOptResponseEl = <ApiError parameter="Cost Optimization" />;
  } else if (costInsightLoading) {
    costOptResponseEl = <ApiLoading parameter="Cost Optimization" />;
  } else if (costInsightMetricObject) {
    costOptResponseEl = (
      <Link className="text-white rounded-5" to="/costoptimization">
        <ErrorBoundary>
          <CostOptimizationCard
            licenseName={props.licenseData?.data?.licenseName}
            monthlySavings={
              costInsightMetricObject.costInsightMonthlySavingsCost
            }
            accounts={props.accounts}
          />
        </ErrorBoundary>
      </Link>
    );
  }

  //Generating UI element for security posture
  if (securityPostureApiError) {
    securityPostureResponseEl = <ApiError parameter="Security Posture" />;
  } else if (securityPostureLoading) {
    securityPostureResponseEl = <ApiLoading parameter="Security Posture" />;
  } else if (securityPostureMetricObject) {
    securityPostureResponseEl = (
      <Link className="text-white rounded-5" to="/Security">
        <ErrorBoundary>
          <SecurityScoreCard
            criticalValue={
              securityPostureMetricObject.securityPostCriticalTotal
            }
            highValue={securityPostureMetricObject.securityPostHighTotal}
            mediumValue={securityPostureMetricObject.securityPostModerateTotal}
            lowValue={securityPostureMetricObject.securityPostLowTotal}
            securityScorePercentage={parseFloat(
              securityPostureMetricObject.securityPostSecPercent
            ).toFixed(2)}
            resourcePassedCount={
              securityPostureMetricObject.resourcePassedCount
            }
            resourceFailedCount={
              securityPostureMetricObject.resourceFailedCount
            }
          />
        </ErrorBoundary>
      </Link>
    );
  }

  //Generating UI element for event data
  eventDataResponseEl = (
    <Link className="text-white rounded-5" to="/eventinsight">
      <ErrorBoundary>
        <EventInsightInforCard
          licenseName={props.licenseData?.data?.licenseName}
          orgCode={orgCode}
          accToken={token}
          propsusername={username}
          dashboardKey={'Landing Page'}
        />
      </ErrorBoundary>
    </Link>
  );

  const handleSubscribeClick = () => {
    if (profile?.userRoles?.includes("Administrator")) {
      setIsSubscribeModalOpen(true);
    } else {
      setContactAdminOpen(true);
    }
    if (profile?.userRoles?.includes("Administrator") && configInfoOpen) {
      setConfigInfoOpen((currentConfigInfoOpen) => false);
    }
  };

  responseEl = (
    <ErrorBoundary>
      <div className={styles.maingrid}>
        {/* Grid for Scrollable items */}
        <div className={styles.scrollablecardcontainer}>
          {/* Grid for three leading cards */}
          <div className={styles.leadingcardgrid}>
            {/* Grid Item for Security Score */}
            <div className={styles.securitypostureitem}>
              {securityPostureResponseEl}
            </div>
            {/* Grid Item for Compliance Score */}
            <div className={styles.complianceitem}>{complianceResponseEl}</div>
            {/* Grid Item for Cost Insight */}
            <div className={styles.costinsightitem}>
              {costInsightResponseEl}
            </div>
            {/* Grid Item for Cost Optimization */}
            <div className={styles.costoptitem}>{costOptResponseEl}</div>
            {/* Grid Item for Event Data */}
            <div
              style={{
                height: "22rem",
              }}
              className={styles.eventdataitem}
            >
              {eventDataResponseEl}
            </div>
          </div>
        </div>
        {/* Grid for fixed position Asset Insight Table */}
        <div className={styles.fixedcardcontainer}>
          <div>{assetInsightResponseEl}</div>
        </div>
      </div>
    </ErrorBoundary>
  );

  // Calculate whether expiration time is in the next 7 days.
  if (props.licenseData?.data?.expiration_date !== null) {
    const expirationDate = props.licenseData?.data?.expiration_date;
    const expirationTime =
      new Date(expirationDate).getTime() -
      new Date(expirationDate).getTimezoneOffset() * 60 * 1000;
    const currentTime = new Date().getTime();
    const oneHourInMillis = 60 * 60 * 1000;
    // console.log("7D cal: currentTime: ", currentTime);
    // console.log("7D cal: expirationTime: ", expirationTime);
    if (expirationTime - currentTime <= oneHourInMillis * 24 * 7) {
      const daysLeft = Math.ceil(
        (expirationTime - currentTime) / (oneHourInMillis * 24 * 1)
      );
      lastSevenDays = true;
      if (daysLeft === 1) {
        daysLeftMessage = `Your free trial ends today. Please subscribe to gain full access!  `;
      } else {
        daysLeftMessage = `Your free trial ends in ${daysLeft} days. Please subscribe to gain full access!  `;
      }
      // console.log("7D cal: lastSevenDays: true");
    } else {
      lastSevenDays = false;
      // console.log("7D cal: lastSevenDays: false");
    }
  }
  subscribeInfoBarEl = (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={configInfoOpen}
      onClose={() => setConfigInfoOpen(false)}
    >
      <Alert
        onClose={() => setConfigInfoOpen(false)}
        sx={{ width: "100%", bgcolor: "whitesmoke", color: "#323232" }}
        icon={false}
        severity="success"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "4rem",
            justifyContent: "space-between",
          }}
        >
          <div>{daysLeftMessage}</div>
          <div style={{ alignSelf: "self-end" }}>
            <button
              className="btn btn-primary"
              // variant="contained"
              // size="small"
              // color="secondary"
              onClick={handleSubscribeClick}
            >
              Subscribe
            </button>
          </div>
        </div>
      </Alert>
    </Snackbar>
  );

  if (props.licenseData?.data?.createdDate !== null) {
    const createdDate = props.licenseData?.data?.createdDate;
    const createdTime =
      new Date(createdDate).getTime() -
      new Date(createdDate).getTimezoneOffset() * 60 * 1000;
    const currentTime = new Date().getTime();
    const oneHourInMillis = 60 * 60 * 1000;
    if (currentTime - createdTime <= oneHourInMillis) {
      return (
        <div className="page-content" style={{ background: "#EAEAEA" }}>
          <InaccessibleResources message="Please check back in an hour as Cloudcatcher is scanning your environment!" />
        </div>
      );
    }
  }
  if (isAccountConfigured !== null && !isAccountConfigured) {
    if (accountStatus === "INACTIVE") {
      awsAccountConfigEl = (
        <div
          style={{
            backgroundColor: "lightgrey",
            fontSize: "16px",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            All the AWS accounts in this organization are inactive. Please add a
            new AWS account or activate the inactive accounts through the{" "}
            <a href="/configuration/accounts" style={{ color: "blue" }}>
              configuration
            </a>{" "}
            menu on the left.
          </div>
        </div>
      );
    } else if (accountStatus === "NOACCOUNT") {
      awsAccountConfigEl = (
        <div
          className="page-content"
          style={{
            backgroundColor: "lightgrey",
            fontSize: "16px",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            This organization does not have any AWS account on-boarded. Please
            add an AWS account through{" "}
            <a href="/configuration/accounts" style={{ color: "blue" }}>
              configuration
            </a>{" "}
            menu on the left.
          </div>
        </div>
      );
    }
  }

  return (
    <ErrorBoundary>
      <div className="page-content" style={{ background: "#EAEAEA" }}>
        {currentLicense &&
          freeLicenses.includes(currentLicense) &&
          lastSevenDays &&
          subscribeInfoBarEl}
        {awsAccountConfigEl ? awsAccountConfigEl : responseEl}
        {/* main div ends here */}
      </div>
      {isSubscribeModalOpen && (
        <SubscribeModal
          isOpen={isSubscribeModalOpen}
          closeModal={() => setIsSubscribeModalOpen(false)}
        />
      )}
      {contactAdminOpen && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={configInfoOpen}
          onClose={() => setContactAdminOpen(false)}
        >
          <Alert
            onClose={() => setContactAdminOpen(false)}
            sx={{ width: "100%", bgcolor: "#323232", color: "whitesmoke" }}
            icon={false}
            severity="success"
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ alignSelf: "self-end" }}>
                <>
                  Please contact your organization's CloudCatcher administrator!
                </>
              </div>
            </div>
          </Alert>
        </Snackbar>
      )}
    </ErrorBoundary>
  );
};

const mapStatetoProps = (state) => {
  const { accounts, reg_acct_error, organizations } = state.orgAccount;
  const {
    assetInsightMetric,
    assetInsightMetricError,
    assetInsightMetricLoading,

    complianceMetric,
    complianceMetricError,
    complianceMetricLoading,

    costInsightMetric,
    costInsightMetricError,
    costInsightMetricLoading,

    securityPostureMetric,
    securityPostureMetricError,
    securityPostureMetricLoading,
  } = state.metricInfo;

  return {
    accounts,
    organizations,
    reg_acct_error,

    assetInsightMetric,
    assetInsightMetricError,
    assetInsightMetricLoading,

    complianceMetric,
    complianceMetricError,
    complianceMetricLoading,

    costInsightMetric,
    costInsightMetricError,
    costInsightMetricLoading,

    securityPostureMetric,
    securityPostureMetricError,
    securityPostureMetricLoading,

    licenseData: state.licensing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListOfOrgsAndAccts: () => {
      dispatch(getListOfOrgsAndAccts());
    },
    getAssetInsightMetricInfo: (accCodes, orgCode) => {
      dispatch(getAssetInsightMetric(accCodes, orgCode));
    },
    getComplianceMetricInfo: (accCodes, orgCode) => {
      dispatch(getComplianceMetric(accCodes, orgCode));
    },
    getCostInsightMetricInfo: (accCodes, orgCode) => {
      dispatch(getCostInsightMetric(accCodes, orgCode));
    },
    getSecurityPostureMetricInfo: (accCodes, orgCode) => {
      dispatch(getSecurityPostureMetric(accCodes, orgCode));
    },
    getLicensingData: () => {
      dispatch(getLicensingData());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(LandingPage);
