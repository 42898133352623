import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import Route53 from "./HostedZones";
import FirewallRuleGroups from "./FirewallRuleGroups";
import HealthCheck from "./HealthCheck";
import RegisteredDomain from "./RegisteredDomain";
import ResolverEndpoints from "./ResolverEndpoints";
import ResolverVPCs from "./ResolverVPCs";
import TrafficPolicies from "./TrafficPolicies";

const Route53Dashboard = () => {
  const [activeTab, setActiveTab] = useState("Route53");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Route53":
        return <Route53 />;
      case "FirewallRuleGroups":
        return <FirewallRuleGroups />;
      case "HealthCheck":
        return <HealthCheck />;
      case "RegisteredDomain":
        return <RegisteredDomain />;
      case "ResolverEndpoints":
        return <ResolverEndpoints />;
      case "ResolverVPCs":
        return <ResolverVPCs />;
      case "TrafficPolicies":
        return <TrafficPolicies />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Hosted Zones" value="Route53" />
        <Tab label="Health Check " value="HealthCheck" />
        <Tab label="Traffic Policies " value="TrafficPolicies" />
        <Tab label="Registered Domain " value="RegisteredDomain" />
        <Tab label="Firewall Rule Groups " value="FirewallRuleGroups" />
        <Tab label="Resolver VPCs " value="ResolverVPCs" />
        <Tab label="Resolver Endpoints " value="ResolverEndpoints" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default Route53Dashboard;
