import React from "react";
import loadingGif from "./../../../assets/images/aws/loadingGif.gif";

const ApiLoading = ({ parameter }) => {
  return (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",
            color: "gray"
        }}
    >
        <div>
            <img src={loadingGif} height={80} alt="Loading Spinner" />
        </div>
        <div>We are retrieving your latest data for {parameter}.</div>
    </div>
  );
};

export default ApiLoading;