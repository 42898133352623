import React, { useMemo } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import DonutChart from "./DonutChart";
import AssetManagementInfoCard from "./AssetManagementInfoCard";
import { useAccessibleUserAccounts } from "../../../components/Common/HelperComponents/CustomHooks";
import { useOrganizations } from "../../../components/Common/HelperComponents/GetDataFromStore";
import { useAssetInsightData } from "./useAssetInsightData";
import ApiLoading from "../components/ApiLoading";
import ApiError from "../components/ApiError";
import { categorizeAssets } from "./categorizeAssets";
import { useAccountSummaryData } from "../AccountsSummary/useAccountSummaryData";

const AssetInsightCard = () => {
  const theme = useTheme();
  const accessibleAccounts = useAccessibleUserAccounts();
  const organizations = useOrganizations();
  const accountsList = useAccountSummaryData();
  const activeAccounts = accountsList.filter(
    (account) => account.status === "ACTIVE"
  ).length;
  const inactiveAccounts = accountsList.filter(
    (account) => account.status === "INACTIVE"
  ).length;

  const accCodes = useMemo(
    () => accessibleAccounts.map((account) => account.accountCode),
    [accessibleAccounts]
  );
  const orgCode = useMemo(() => organizations?.[0]?.orgcode, [organizations]);

  const { data, loading, error } = useAssetInsightData(accCodes, orgCode);

  const categorizedData = useMemo(() => {
    if (!data) return [];
    return categorizeAssets(data);
  }, [data]);

  const assetCategories = useMemo(() => {
    if (!categorizedData) return [];
    return Object.entries(categorizedData).map(([category, value]) => ({
      name: category,
      value: value,
    }));
  }, [categorizedData]);

  const totalAssets = assetCategories.reduce(
    (sum, item) => sum + item.value,
    0
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        padding: "8px 12px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          mb: 1,
          paddingBottom: "2px",
          borderBottom: `1px solid`,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", color: "text.primary" }}
        >
          Asset Insight
        </Typography>

        <Box display="flex" alignItems="center">
          {/* Green Active Accounts Badge */}
          <Tooltip title="Active Accounts" arrow>
            <Box
              sx={{
                border: "2px solid #4caf50", // Green border
                color: "#4caf50", // Green text
                borderRadius: "50%", // Circular badge
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {activeAccounts}
              </Typography>
            </Box>
          </Tooltip>

          {/* Divider */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: 0.5, alignSelf: "center", height: "18px" }}
          />

          {/* Red Inactive Accounts Badge */}
          <Tooltip title="Inactive Accounts" arrow>
            <Box
              sx={{
                border: "2px solid #f44336", // Red border
                color: "#f44336", // Red text
                borderRadius: "50%", // Circular badge
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {inactiveAccounts}
              </Typography>
            </Box>
          </Tooltip>

          {/* Divider */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: 0.5, alignSelf: "center", height: "18px" }}
          />

          {/* Drag Icon */}
          <IconButton
            aria-label="drag-handle"
            className="drag-handle"
            sx={{ color: "text.secondary", fontSize: "20px", p: 0 }}
          >
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <DonutChart data={assetCategories} totalAssets={totalAssets} />
      </Box>

      {loading ? (
        <ApiLoading parameter="Asset Insight" />
      ) : error ? (
        <ApiError parameter="Asset Insight" />
      ) : (
        data && (
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              pr: 1,
              mt: 2,
              "&::-webkit-scrollbar": {
                width: "5px", // Slim scrollbar width
                borderRadius: "10px", // Rounded scrollbar
              },
              "&::-webkit-scrollbar-track": {
                background: "background.default",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "grey.400",
                borderRadius: "10px", // Rounded corners for the thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "grey.500",
              },
              scrollbarWidth: "thin", // Firefox slim scrollbar
            }}
          >
            <AssetManagementInfoCard metricObject={data} />
          </Box>
        )
      )}
    </Box>
  );
};

export default AssetInsightCard;
