import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import RuleResEmbed from "./RuleResEmbed";
import TrendAnalyzer from "./TrendAnalyzer";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const Security = ({ licenseName, message }) => {
  const [activeTab, setActiveTab] = useState("security");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "security":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          return <RuleResEmbed />;
        }
      case "trend-analyzer":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          return <TrendAnalyzer />;
        }
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Security" value="security" />
        <Tab label="Trend Analyzer" value="trend-analyzer" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default Security;
