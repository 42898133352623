import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import EventInsightInforCard from "../../LandingPage/components/EventInsightInfoCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const EventInsight = () => {
  const history = useHistory();

  const handleGoTo = () => {
    console.log("Go-to action clicked");
    history.push("/eventinsight");
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        padding: "8px 12px",
        height: "320px", // Make sure the height is set
        display: "flex",
        flexDirection: "column",
        position: "relative", // Ensure proper stacking context
      }}
    >
      {/* Header with Icons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          mb: 1,
          paddingBottom: "2px",
          borderBottom: `1px solid`,
          zIndex: 10, // Keep the header above the iframe
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", color: "text.primary" }}
        >
          Event Insight
        </Typography>
        <Box display="flex" alignItems="center">
          <Tooltip title="Open Detailed View">
            <IconButton
              aria-label="go-to"
              sx={{ color: "text.secondary", fontSize: "20px" }}
              onClick={handleGoTo}
            >
              <OpenInNewOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ alignSelf: "center", height: "18px" }}
          />
          <IconButton
            aria-label="settings"
            className="drag-handle"
            sx={{ color: "text.secondary", fontSize: "20px" }}
          >
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {/* Event Cards */}
      <Box sx={{ flexGrow: 1, position: "relative", overflow: "hidden" }}>
        <Grid container spacing={0.5} sx={{ flexGrow: 1, height: "100%" }}>
          <EventInsightInforCard dashboardKey={"Landing Page"} />
        </Grid>
      </Box>
    </Box>
  );
};

export default EventInsight;
