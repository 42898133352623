import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getComplianceMetric } from "../../../store/actions";

export const useComplianceData = (accCodes, orgCode) => {
  const dispatch = useDispatch();
  
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const complianceMetric = useSelector(
    (state) => state.metricInfo.complianceMetric
  );
  const complianceMetricLoading = useSelector(
    (state) => state.metricInfo.complianceMetricLoading
  );
  const complianceMetricError = useSelector(
    (state) => state.metricInfo.complianceMetricError
  );

  // To track if the API call has already been made
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (accCodes.length > 0 && orgCode && !hasFetchedData.current) {
      dispatch(getComplianceMetric(accCodes, orgCode));
      hasFetchedData.current = true; // Mark the call as done
    }
  }, [dispatch, accCodes, orgCode]);

  useEffect(() => {
    if (complianceMetric) {
      const metricObject = apiDataToDynamicComplianceMetricObject(complianceMetric);
      setData(metricObject);
    }
    setLoading(complianceMetricLoading);
    setError(complianceMetricError ? "Error in Compliance API" : null);
  }, [complianceMetric, complianceMetricLoading, complianceMetricError]);

  return { data, loading, error };
};

const apiDataToDynamicComplianceMetricObject = (apiData) => {
  const complianceData = Object.values(apiData).filter(
    (item) => item.complianceRef !== undefined && item.AvgFixed !== undefined
  );

  return {
    Compliance: complianceData.map((item) => ({
      Percentage: parseFloat(item.AvgFixed.toFixed(2)),
      Name: item.complianceRef,
    })),
  };
};
