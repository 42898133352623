import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSecurityPostureMetric } from "../../../store/actions";

export const useSecurityScoreData = (accCodes, orgCode) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const securityPostureMetric = useSelector(
    (state) => state.metricInfo.securityPostureMetric
  );
  const securityPostureMetricLoading = useSelector(
    (state) => state.metricInfo.securityPostureMetricLoading
  );
  const securityPostureMetricError = useSelector(
    (state) => state.metricInfo.securityPostureMetricError
  );

  const hasFetched = useRef(false); // Add a ref to track if the API call was made

  useEffect(() => {
    if (accCodes && orgCode && !hasFetched.current) {
      dispatch(getSecurityPostureMetric(accCodes, orgCode));
      hasFetched.current = true; // Set the ref to true after the API call is made
    }
  }, [dispatch, accCodes, orgCode]);

  useEffect(() => {
    if (securityPostureMetric) {
      const metricObject = apiDataToSecurityPostureMetricObject(
        securityPostureMetric
      );
      setData(metricObject);
    }
    setLoading(securityPostureMetricLoading);
    setError(
      securityPostureMetricError ? "Error in Security Posture API" : null
    );
  }, [
    securityPostureMetric,
    securityPostureMetricLoading,
    securityPostureMetricError,
  ]);

  return { data, loading, error };
};

const apiDataToSecurityPostureMetricObject = (apiData) => {
  let metricObject = {};

  metricObject.securityPostSecPercent = parseFloat(apiData["AvgFixed"]["Failed"]).toFixed(2);
  metricObject.securityPostCriticalTotal =
    typeof apiData["critical"]["Failed"] === "string"
      ? parseInt(apiData["critical"]["Failed"])
      : apiData["critical"]["Failed"];
  metricObject.securityPostHighTotal =
    typeof apiData["high"]["Failed"] === "string"
      ? parseInt(apiData["high"]["Failed"])
      : apiData["high"]["Failed"];
  metricObject.securityPostModerateTotal =
    typeof apiData["moderate"]["Failed"] === "string"
      ? parseInt(apiData["moderate"]["Failed"])
      : apiData["moderate"]["Failed"];
  metricObject.securityPostLowTotal =
    typeof apiData["low"]["Failed"] === "string"
      ? parseInt(apiData["low"]["Failed"])
      : apiData["low"]["Failed"];

  metricObject.resourcePassedCount =
    typeof apiData["DistinctCountArnLocator"]["Passed"] === "string"
      ? parseInt(apiData["DistinctCountArnLocator"]["Passed"])
      : apiData["DistinctCountArnLocator"]["Passed"];
  metricObject.resourceFailedCount =
    typeof apiData["DistinctCountArnLocator"]["Failed"] === "string"
      ? parseInt(apiData["DistinctCountArnLocator"]["Failed"])
      : apiData["DistinctCountArnLocator"]["Failed"];

  return metricObject;
};
