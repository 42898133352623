import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getListOfOrgsAndAccts } from "../../../store/actions";
import { fetchKibanaDashboards } from "../../../store/kibanaDashboards/kibanaDashboardsSlice";

// Custom hook to fetch and return accounts
export const useAccounts = () => {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.orgAccount.accounts);
  const accountsLoading = useSelector((state) => state.orgAccount.loading);
  console.log("🚀 ~ useAccounts ~ accounts:", accounts);

  useEffect(() => {
    if (!accounts || accounts.length === 0) {
      if(!accountsLoading) {
        console.log("🚀 ~ useAccounts ~ getListOfOrgsAndAccts():");
        dispatch(getListOfOrgsAndAccts());
      }
    }
  }, [accounts, accountsLoading, dispatch]);

  return accounts;
};

export const useOrganizations = () => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.orgAccount.organizations);

  useEffect(() => {
    if (!organizations || organizations.length === 0) {
      dispatch(getListOfOrgsAndAccts());
    }
  }, [organizations, dispatch]);

  return organizations;
};

// Get Organizations
export const useOrganization = () => {
  const dispatch = useDispatch();
  const orgsLoading = useSelector((state) => state.orgAccount.loading);

  // Use shallowEqual to prevent unnecessary re-renders
  const organizations = useSelector(
    (state) => state.orgAccount.organizations,
    shallowEqual
  );

  // Memoize the return value
  const orgData = useMemo(() => {
    console.log("🚀 ~ useOrganizations ~ organizations:", organizations);
    return organizations && organizations.length > 0 ? organizations[0] : null;
  }, [organizations]);

  useEffect(() => {
    if (!organizations || organizations.length === 0) {
      if (!orgsLoading) {
        console.log("🚀 ~ useOrganizations ~ getListOfOrgsAndAccts():");
        dispatch(getListOfOrgsAndAccts());
      }
    }
  }, [organizations, orgsLoading, dispatch]); // Keep only dispatch in dependency array

  return orgData;
};

// Get Kibana Dashboards URL
export const useFetchKibanaDashboards = () => {
  const organization = useOrganization();
  const orgcode = organization?.orgcode;

  const dispatch = useDispatch();
  const { dashboards, isLoading, errorMessage } = useSelector(
    (state) => state.kibanaDashboards
  );

  useEffect(() => {
    if (orgcode) {
      if ((!dashboards || dashboards.length === 0) && !errorMessage) {
        if (!isLoading) {
          console.log("🚀 ~ useFetchKibanaDashboards ~ fetchKibanaDashboards():");
          dispatch(fetchKibanaDashboards(orgcode));
        }
      }
      
    }
  }, [dispatch, isLoading, dashboards, orgcode]);

  return useMemo(
    () => ({ dashboards, isLoading, errorMessage }),
    [dashboards, isLoading, errorMessage]
  );
};