import React from "react";
import SecurityScore from "./SecurityScore";
import Compliance from "./Compliance";
import AccountSummary from "./AccountsSummary";
import AssetInsightCard from "./AssetInsight";
import CostInsight from "./CostInsight";
import EventInsight from "./EventInsight"; // Importing the EventInsight component
import { ResponsiveGridLayout } from "./components/ResponsiveGridLayout";

const DashboardPage = () => {
  // Breakpoints for different screen sizes
  const breakpoints = { lg: 1600, md: 1200, sm: 768, xs: 480, xxs: 0 };
  const cols = { lg: 16, md: 12, sm: 6, xs: 4, xxs: 2 };

  const layouts = {
    lg: [
      { i: "assetInsight", x: 0, y: 0, w: 4, h: 6 },
      { i: "costInsight", x: 12, y: 3, w: 4, h: 3 },
      { i: "eventInsight", x: 4, y: 6, w: 12, h: 3 },
      { i: "securityScore", x: 4, y: 0, w: 4, h: 3 },
      { i: "compliance", x: 8, y: 0, w: 4, h: 3 },
    ],
    md: [
      { i: "assetInsight", x: 0, y: 0, w: 3, h: 6 },
      { i: "costInsight", x: 12, y: 3, w: 3, h: 3 },
      { i: "eventInsight", x: 3, y: 6, w: 9, h: 3 },
      { i: "securityScore", x: 3, y: 0, w: 3, h: 3 },
      { i: "compliance", x: 6, y: 0, w: 3, h: 3 },
    ],
    sm: [
      { i: "assetInsight", x: 0, y: 0, w: 6, h: 6 },
      { i: "costInsight", x: 6, y: 6, w: 6, h: 3 },
      { i: "eventInsight", x: 0, y: 9, w: 6, h: 3 },
      { i: "securityScore", x: 0, y: 12, w: 6, h: 3 },
      { i: "compliance", x: 0, y: 15, w: 6, h: 3 },
    ],
    xs: [
      { i: "assetInsight", x: 0, y: 0, w: 4, h: 6 },
      { i: "costInsight", x: 4, y: 6, w: 4, h: 3 },
      { i: "eventInsight", x: 0, y: 9, w: 4, h: 3 },
      { i: "securityScore", x: 0, y: 12, w: 4, h: 3 },
      { i: "compliance", x: 0, y: 15, w: 4, h: 3 },
    ],
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      breakpoints={breakpoints}
      cols={cols}
      layouts={layouts}
      rowHeight={100}
      isDraggable={true}
      isResizable={false}
      draggableHandle=".drag-handle"
    >
      <div key="costInsight">
        <CostInsight />
      </div>
      <div key="eventInsight">
        {" "}
        {/* Added EventInsight */}
        <EventInsight />
      </div>
      <div key="securityScore">
        <SecurityScore />
      </div>
      <div key="compliance">
        <Compliance />
      </div>
      <div key="assetInsight">
        <AssetInsightCard />
      </div>
    </ResponsiveGridLayout>
  );
};

export default DashboardPage;
