import React from "react";
import styles from "./landingpage.module.css";

const CardComponent = ({ title, amount, shortTitle, color }) => (
  <div className={styles.card}>
    <div className={styles.cardTitle}>{title}</div>
    <div className={styles.cardAmount} style={{color:color}}>${amount}</div>
    <div className={styles.cardShortTitle}>{shortTitle}</div>
  </div>
);

export default CardComponent;