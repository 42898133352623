import React from 'react';
import { Box, Skeleton } from '@mui/material';


// Example usagge for ApiLoading Component
{/* <ApiLoading variant="card" height={150} width="100%" />
<ApiLoading variant="table" height={30} width="100%" />
<ApiLoading variant="list" width="100%" />
<ApiLoading variant="chart" width={150} height={150} /> */}


const ApiLoading = ({ variant = 'card', width = '100%', height = 150 }) => {
  let content;

  switch (variant) {
    case 'card':
      content = (
        <>
          <Skeleton variant="rectangular" width={width} height={height} animation="wave" />
          <Box sx={{ mt: 2 }}>
            <Skeleton variant="text" width="80%" height={24} animation="wave" />
            <Skeleton variant="text" width="60%" height={24} animation="wave" />
          </Box>
        </>
      );
      break;

    case 'table':
      content = (
        <>
          <Skeleton variant="rectangular" width={width} height={40} animation="wave" />
          {[...Array(5)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={width} height={30} animation="wave" sx={{ mt: 1 }} />
          ))}
        </>
      );
      break;

    case 'chart':
      content = (
        <Skeleton variant="circular" width={150} height={150} animation="wave" />
      );
      break;

    case 'list':
      content = (
        <>
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" width={width} height={30} animation="wave" sx={{ mt: 1 }} />
          ))}
        </>
      );
      break;

    default:
      content = (
        <Skeleton variant="rectangular" width={width} height={height} animation="wave" />
      );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: 2,
        boxShadow: '0 0 15px rgba(0,0,0,0.15)',
        padding: 2,
        width: '100%',
        maxWidth: variant === 'card' ? '400px' : '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {content}
    </Box>
  );
};

export default ApiLoading;
