import React from "react";
import EmbedShell from "../../components/Common/EmbedShell";

const SNS = () => {
  const VIEW_URL = process.env.REACT_APP_SNS_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const SQS = () => {
  const VIEW_URL = process.env.REACT_APP_SQS_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const Lambda = () => {
  const VIEW_URL = process.env.REACT_APP_LAMBDA_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const EKS = () => {
  const VIEW_URL = process.env.REACT_APP_EKS_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const ECR = () => {
  const VIEW_URL = process.env.REACT_APP_ECR_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const ACM = () => {
  const VIEW_URL = process.env.REACT_APP_ACM_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const KMS = () => {
  const VIEW_URL = process.env.REACT_APP_KMS_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const CloudTrail = () => {
  const VIEW_URL = process.env.REACT_APP_CLOUDTRAIL_assetinsight_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const APIGateway = () => {
  const VIEW_URL = process.env.REACT_APP_APIGATEWAY_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const CloudFront = () => {
  const VIEW_URL = process.env.REACT_APP_CF_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const IAM = () => {
  const VIEW_URL = process.env.REACT_APP_IAM_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const S3 = () => {
  const VIEW_URL = process.env.REACT_APP_S3_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const EFS = () => {
  const VIEW_URL = process.env.REACT_APP_EFS_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const SecretsManager = () => {
  const VIEW_URL = process.env.REACT_APP_SECRETMANAGER_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const SystemsManager = () => {
  const VIEW_URL = process.env.REACT_APP_SYSTEMS_MANAGER_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

const AutoScaling = () => {
  const VIEW_URL = process.env.REACT_APP_AUTOSCALING_VIEW;

  return <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
};

export {
  SNS,
  SQS,
  Lambda,
  EKS,
  ECR,
  ACM,
  KMS,
  CloudTrail,
  APIGateway,
  CloudFront,
  IAM,
  S3,
  EFS,
  SecretsManager,
  AutoScaling,
  SystemsManager,
};
