import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { PrivateEcr, PublicEcr } from "./EcrDashboards";

const EcrDashboard = () => {
  const [activeTab, setActiveTab] = useState("PrivateEcr");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "PrivateEcr":
        return <PrivateEcr />;
      case "PublicEcr":
        return <PublicEcr />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Private ECR" value="PrivateEcr" />
        <Tab label="Public ECR" value="PublicEcr" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default EcrDashboard;
