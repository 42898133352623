import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DonutChart from "./DonutChart";
import DashboardCard from "../components/DashboardCard";
import { Box, IconButton, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import { useTheme } from "@mui/material/styles";
import { useAccounts } from "../../../components/Common/HelperComponents/GetDataFromStore";
import { useAccountSummaryData } from "./useAccountSummaryData";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AccountSummary = () => {
  const theme = useTheme();

  const history = useHistory();

  const accountsList = useAccountSummaryData();
  console.log("🚀 ~ AccountSummary ~ filteredAccounts:", accountsList);

  const activeAccounts = accountsList.filter(
    (account) => account.status === "ACTIVE"
  ).length;
  const inactiveAccounts = accountsList.filter(
    (account) => account.status === "INACTIVE"
  ).length;
  const totalAccounts = activeAccounts + inactiveAccounts;

  const data = [
    {
      name: "Active",
      value: activeAccounts,
      color: theme.palette.success.main,
    },
    {
      name: "Inactive",
      value: inactiveAccounts,
      color: theme.palette.error.main,
    },
  ];

  const handleGoTo = () => {
    console.log("Go-to action clicked");
    history.push("/configuration/accounts");
  };

  return (
    <DashboardCard
      title="Cloud Accounts"
      chart={<DonutChart data={data} total={totalAccounts} />}
      actions={[
        {
          label: "go-to",
          icon: (
            <IconButton onClick={handleGoTo} size="small" sx={{ p: 0 }}>
              <OpenInNewOutlinedIcon fontSize="small" />
            </IconButton>
          ),
        },
      ]}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            sx={{ color: theme.palette.success.main, fontSize: "12px", mr: 1 }}
          />
          <Typography
            variant="body2"
            sx={{ color: theme.palette.neutral.main }}
          >
            Active Accounts
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: theme.palette.neutral.main }}>
          {activeAccounts}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            sx={{ color: theme.palette.error.main, fontSize: "12px", mr: 1 }}
          />
          <Typography
            variant="body2"
            sx={{ color: theme.palette.neutral.main }}
          >
            Inactive Accounts
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: theme.palette.neutral.main }}>
          {inactiveAccounts}
        </Typography>
      </Box>
    </DashboardCard>
  );
};

export default AccountSummary;
