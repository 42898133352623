import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

const ResolverVPCs = () => {
  const VIEW_URL = process.env.REACT_APP_ROUTE53_Resolver_VPCs_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export default ResolverVPCs;
