import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

export const ECS = () => {
  const VIEW_URL = process.env.REACT_APP_ECS_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const TaskDefinition = () => {
  const VIEW_URL = process.env.REACT_APP_ECS_TASK_DEFINITION_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};
