export const categorizeAssets = (data) => {
    const getSafeValue = (value) => (value !== undefined ? value : 0);
  
    return {
      "App Integration": getSafeValue(data.snsTopicsTotal) + getSafeValue(data.sqsTotal) + getSafeValue(data.eventBridgeTotal),
      "Compute": getSafeValue(data.ec2Total) + getSafeValue(data.lambdaFunctionsTotal) + getSafeValue(data.elbTotal),
      "Containers": getSafeValue(data.eksClustersTotal) + getSafeValue(data.ecsClustersTotal) + getSafeValue(data.ecrTotal),
      "Cryptography": getSafeValue(data.acmCertificatesTotal) + getSafeValue(data.kmsTotalKeys),
      "Database": getSafeValue(data.dynamoDb) + getSafeValue(data.rds),
      "Management & Governance": getSafeValue(data.cloudTrailsTotal) + getSafeValue(data.cloudWatchAlarmsTotal) + getSafeValue(data.autoScalingTotal) + getSafeValue(data.systemsManagerTotal),
      "Networking": getSafeValue(data.apiTotal) + getSafeValue(data.cloudFrontDistTotal) + getSafeValue(data.vpcTotal) + getSafeValue(data.route53) + getSafeValue(data.wafTotal),
      "Identity & Compliance": getSafeValue(data.iamUsersTotal) + getSafeValue(data.iamCustomerCreatedRoles) + getSafeValue(data.iamCustomerPolicies) + getSafeValue(data.secretManagerTotal) + getSafeValue(data.guardDutyTotal),
      "Storage": getSafeValue(data.s3TotalBuckets) + getSafeValue(data.efsTotal) + getSafeValue(data.backupTotal),
    };
  };
  