import React from "react";
import { Link } from "react-router-dom";

import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";

import { Box, Tooltip as MuiTooltip, Typography } from "@mui/material";

import SNSIcon from "./../../../assets/images/aws/SNS.png";
import RDSIcon from "./../../../assets/images/aws/RDS.png";
import DynamoDBIcon from "./../../../assets/images/aws/DynamoDB.png";
import EFSIcon from "./../../../assets/images/aws/EFS.png";
import LambdaIcon from "./../../../assets/images/aws/lambda.png";
import ACMIcon from "./../../../assets/images/aws/ACM.png";
import GuardDutyIcon from "./../../../assets/images/aws/GuardDuty.png";
import IAMIcon from "./../../../assets/images/aws/IAM.png";
import ELBIcon from "./../../../assets/images/aws/ELB.png";
import EKSIcon from "./../../../assets/images/aws/EKS.png";
import ECSIcon from "./../../../assets/images/aws/ECS.png";
import EC2Icon from "./../../../assets/images/aws/EC2.png";
import CloudTrailIcon from "./../../../assets/images/aws/CloudTrail.png";
import CloudWatchIcon from "./../../../assets/images/aws/CloudWatch.png";
import APIGatewayIcon from "./../../../assets/images/aws/APIGateway.png";
import CloudFrontIcon from "./../../../assets/images/aws/CloudFront.png";
import VPCIcon from "./../../../assets/images/aws/VPC.png";
import S3Icon from "./../../../assets/images/aws/S3.png";
import Route53Icon from "./../../../assets/images/aws/Route53.png";
import KMSIcon from "./../../../assets/images/aws/KMS.png";
import WAFIcon from "./../../../assets/images/aws/WAF.png";
import SQSIcon from "./../../../assets/images/aws/SQS.png";
import BackupIcon from "./../../../assets/images/aws/Backup.png";
import ECRIcon from "./../../../assets/images/aws/ECR.png";
import SecretManagerIcon from "./../../../assets/images/aws/SecretManager.png";
import AutoScalingIcon from "./../../../assets/images/aws/AutoScaling.png";
import EventBridgeIcon from "./../../../assets/images/aws/EventBridge.png";
import SystemsManagerIcon from "./../../../assets/images/aws/SystemsManager.png";

const columns = [
  {
    field: "icon",
    label: "",
    width: "50px",
    // editable: true,
  },
  {
    field: "asset",
    label: (
      <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
        Asset
      </Typography>
    ),
    width: "50px",
    // editable: true,
  },
  {
    field: "value",
    label: (
      <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
        Count
      </Typography>
    ),
    type: "number",
    width: "20px",
    // editable: true,
  },
];

const AssetManagementInfoCard = ({ metricObject }) => {
  const assets = [
    {
      // id: "1008",
      icon: <img src={ACMIcon} width="24px" alt={"ACM"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="120px">Private Certificates</td>
                <td align="right">{metricObject.acmPrivateCertificates}</td>
              </tr>
              <tr>
                <td width="120px">Public Certificates</td>
                <td align="right">{metricObject.acmPublicCertificates}</td>
              </tr>
              <tr>
                <td width="120px">Private Certificate Authority</td>
                <td align="right">{metricObject.caTotal}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/ACM">
            ACM
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.acmCertificatesTotal,
    },
    {
      // id: "1013",
      icon: <img src={APIGatewayIcon} width="24px" alt={"API Gateway"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="70px">HTTP</td>
                <td align="right">{metricObject.apiHttp}</td>
              </tr>
              <tr>
                <td width="70px">WebSocket</td>
                <td align="right">{metricObject.apiWebSocket}</td>
              </tr>
              <tr>
                <td width="70px">REST</td>
                <td align="right">{metricObject.apiRest}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/APIGateway">
            API Gateway
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.apiTotal,
    },
    {
      // id: "1010",
      icon: <img src={AutoScalingIcon} width="24px" alt={"Auto Scaling"} />,
      asset: (
        <MuiTooltip
          title={
            <table style={{ whiteSpace: "nowrap" }}>
              <tr>
                <td style={{ paddingRight: "10px" }} width="50px">
                  Auto Scaling Group
                </td>
                <td align="right">{metricObject.autoScalingGroup}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: "10px" }} width="50px">
                  Auto Scaling Plan
                </td>
                <td align="right">{metricObject.autoScalingPlan}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/AutoScaling">
            Auto Scaling
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.autoScalingTotal,
    },
    {
      // id: "1007",
      icon: <img src={BackupIcon} width="24px" alt={"Backup"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">Backup Plans</td>
                <td align="right">{metricObject.awsBackupPlans}</td>
              </tr>
              <tr>
                <td width="150px">Backup Vaults</td>
                <td align="right">{metricObject.awsBackupVaults}</td>
              </tr>
              <tr>
                <td width="150px">Protected Resources</td>
                <td align="right">
                  {metricObject.awsBackupProtectedResources}
                </td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/Backup">
            Backup
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.backupTotal,
    },
    {
      // id: "1014",
      icon: <img src={CloudFrontIcon} width="24px" alt={"CloudFront"} />,
      asset: (
        <MuiTooltip title="Total Distributions" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/CloudFront">
            CloudFront
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.cloudFrontDistTotal,
    },
    {
      // id: "1011",
      icon: <img src={CloudTrailIcon} width="24px" alt={"CloudTrail"} />,
      asset: (
        <MuiTooltip title="Total Cloud Trails" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/CloudTrail">
            CloudTrail
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.cloudTrailsTotal,
    },
    {
      // id: "1012",
      icon: <img src={CloudWatchIcon} width="24px" alt={"CloudWatch"} />,
      asset: (
        <MuiTooltip title="Total Number of Metric Alarms" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/CloudWatch">
            CloudWatch
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.cloudWatchAlarmsTotal,
    },
    {
      // id: "1003",
      icon: <img src={DynamoDBIcon} width="24px" alt={"DynamoDB"} />,
      asset: (
        <MuiTooltip title="Total Number of DynamoDB Tables" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/DynamoDB">
            DynamoDB
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.dynamoDb,
    },
    {
      // id: "1010",
      icon: <img src={EC2Icon} width="24px" alt={"EC2 Instances"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="50px">Running</td>
                <td align="right">{metricObject.ec2Running}</td>
              </tr>
              <tr>
                <td width="50px">Stopped</td>
                <td align="right">{metricObject.ec2Stopped}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/EC2">
            EC2 Instances
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.ec2Running + metricObject.ec2Stopped,
    },
    {
      // id: "1007",
      icon: <img src={ECRIcon} width="24px" alt={"ECR"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">Private Repositories</td>
                <td align="right">{metricObject.ecrPrivateResopitories}</td>
              </tr>
              <tr>
                <td width="150px">Public Repositories</td>
                <td align="right">{metricObject.ecrPublicResopitories}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/ECR">
            ECR
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.ecrTotal,
    },
    {
      // id: "1009-2",
      icon: <img src={ECSIcon} width="24px" alt={"ECS Clusters"} />,
      asset: (
        <MuiTooltip title="Total Number of Clusters" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/ECS">
            ECS Clusters
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.ecsClustersTotal,
    },
    {
      // id: "1004",
      icon: <img src={EFSIcon} width="24px" alt={"EFS"} />,
      asset: (
        <MuiTooltip title="Total Number of File Systems" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/EFS">
            EFS
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.efsTotal,
    },
    {
      // id: "1009-1",
      icon: <img src={EKSIcon} width="24px" alt={"EKS Clusters"} />,
      asset: (
        <MuiTooltip title="Total EKS Clusters" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/EKS">
            EKS Clusters
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.eksClustersTotal,
    },
    {
      icon: <img src={EventBridgeIcon} width="24px" alt={"EventBridge"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">Rules Count</td>
                <td align="right">{metricObject.eventBridgeRulesCount}</td>
              </tr>
              <tr>
                <td width="150px">Event Buses Count</td>
                <td align="right">{metricObject.eventBridgeEventBusesCount}</td>
              </tr>
              <tr>
                <td width="150px">Pipes Count</td>
                <td align="right">{metricObject.eventBridgePipesCount}</td>
              </tr>
              <tr>
                <td width="150px">Schedule Count</td>
                <td align="right">{metricObject.eventBridgeScheduleCount}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/EventBridge">
            EventBridge
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.eventBridgeTotal,
    },
    {
      icon: <img src={GuardDutyIcon} width="24px" alt={"Guard Duty"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">Guard Duty Findings</td>
                <td align="right">{metricObject.guardDutyFindings}</td>
              </tr>
              <tr>
                <td width="150px">Protected S3 buckets</td>
                <td align="right">
                  {metricObject.guardDutyProtectedS3Buckets}
                </td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/GuardDuty">
            Guard Duty
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.guardDutyTotal,
    },
    {
      // id: "1007",
      icon: <img src={IAMIcon} width="24px" alt={"IAM"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">IAM Users</td>
                <td align="right">{metricObject.iamUsersTotal}</td>
              </tr>
              <tr>
                <td width="150px">Customer Created Roles</td>
                <td align="right">{metricObject.iamCustomerCreatedRoles}</td>
              </tr>
              <tr>
                <td width="150px">Customer Policies</td>
                <td align="right">{metricObject.iamCustomerPolicies}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/IAM">
            IAM
          </Link>
        </MuiTooltip>
      ),
      value:
        metricObject.iamUsersTotal +
        metricObject.iamCustomerCreatedRoles +
        metricObject.iamCustomerPolicies,
    },
    {
      // id: "1008",
      icon: <img src={KMSIcon} width="24px" alt={"KMS"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="120px">AWS Managed Keys</td>
                <td align="right">{metricObject.kmsAwsManagedKeys}</td>
              </tr>
              <tr>
                <td width="120px">Customer Managed Keys</td>
                <td align="right">{metricObject.kmsCustomerManagedKeys}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/KMS">
            KMS
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.kmsTotalKeys,
    },
    {
      // id: "1005",
      icon: <img src={LambdaIcon} width="24px" alt={"Lambda"} />,
      asset: (
        <MuiTooltip title="Total Lambda Functions" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/Lambda">
            Lambda Functions
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.lambdaFunctionsTotal,
    },
    {
      // id: "1007",
      icon: <img src={ELBIcon} width="24px" alt={"ELB"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">Classic Load Balncers</td>
                <td align="right">{metricObject.elbClassic}</td>
              </tr>
              <tr>
                <td width="150px">Application Load Balancers</td>
                <td align="right">{metricObject.elbApplication}</td>
              </tr>
              <tr>
                <td width="150px">Network Load Balancers</td>
                <td align="right">{metricObject.elbNetwork}</td>
              </tr>
              <tr>
                <td width="150px">Gateway Load Balancers</td>
                <td align="right">{metricObject.elbGateway}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link
            style={{ color: "black" }}
            to="/assetinsight/EC2?tab=LoadBalancer"
          >
            Load Balancers
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.elbTotal,
    },
    {
      // id: "1002",
      icon: <img src={RDSIcon} width="24px" alt={"RDS"} />,
      asset: (
        <MuiTooltip title="Total RDS Instances " placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/RDS">
            RDS
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.rds,
    },
    {
      // id: "1011",
      icon: <img src={Route53Icon} width="24px" alt={"Route53"} />,
      asset: (
        <MuiTooltip title="Total Hosted Zones " placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/Route53">
            Route53
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.route53,
    },
    {
      // id: "1007",
      icon: <img src={S3Icon} width="24px" alt={"S3"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">Public Buckets</td>
                <td align="right">{metricObject.s3PublicBuckets}</td>
              </tr>
              <tr>
                <td width="150px">Unencrypted Buckets</td>
                <td align="right">{metricObject.s3EncryptedBuckets}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="/assetinsight/S3">
            S3
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.s3TotalBuckets,
    },
    {
      // id: "1015",
      icon: <img src={SecretManagerIcon} width="24px" alt={"SecretManager"} />,
      asset: (
        <MuiTooltip title="Total Secrets Manager" placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/SecretsManager">
            Secrets Manager
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.secretManagerTotal,
    },
    {
      // id: "1001",
      icon: <img src={SNSIcon} width="24px" alt={"SNS Topics"} />,
      asset: (
        <MuiTooltip title="Total SNS Topics " placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/SNS">
            SNS
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.snsTopicsTotal,
    },
    {
      // id: "1001",
      icon: <img src={SQSIcon} width="24px" alt={"SQS"} />,
      asset: (
        <MuiTooltip title="Total SQS " placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/SQS">
            SQS
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.sqsTotal,
    },
    {
      // id: "1007",
      icon: <img src={SystemsManagerIcon} width="24px" alt={"S3"} />,
      asset: (
        <MuiTooltip
          title={
            <table>
              <tr>
                <td width="150px">Managed Nodes</td>
                <td align="right">{metricObject.systemsManagerManagedNodes}</td>
              </tr>
              <tr>
                <td width="150px">Config profiles</td>
                <td align="right">
                  {metricObject.systemsManagerAppConfigProfiles}
                </td>
              </tr>
              <tr>
                <td width="150px">Applications</td>
                <td align="right">{metricObject.systemsManagerApplications}</td>
              </tr>
            </table>
          }
          placement="right"
        >
          <Link style={{ color: "black" }} to="#">
            Systems Manager
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.systemsManagerTotal,
    },
    {
      // id: "1015",
      icon: <img src={VPCIcon} width="24px" alt={"VPC"} />,
      asset: (
        <MuiTooltip title="Total VPC's " placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/VPC">
            VPC
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.vpcTotal,
    },
    {
      // id: "1015",
      icon: <img src={WAFIcon} width="24px" alt={"WAF"} />,
      asset: (
        <MuiTooltip title="Total WAF's " placement="right">
          <Link style={{ color: "black" }} to="/assetinsight/WAF">
            WAF
          </Link>
        </MuiTooltip>
      ),
      value: metricObject.wafTotal,
    },
  ];
  return (
    <Box>
      <MDBTable small>
        <MDBTableHead columns={columns}></MDBTableHead>
        <MDBTableBody>
          {assets.map((row) => (
            <tr>
              <td style={{ padding: "0px !important" }}>{row.icon}</td>
              <td style={{ padding: "0px !important" }}>
                <Typography variant="body2">{row.asset}</Typography>
              </td>
              <td align="center" style={{ padding: "0px !important" }}>
                <Typography variant="body2">{row.value}</Typography>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </Box>
  );
};

export default AssetManagementInfoCard;
