import React from "react";
import styles from "./landingpage.module.css";
import CardComponent from "./CardComponent";
import { Alert } from "@mui/material";
import { useHasActiveUserAccounts, useIsCostInsightEnabledForOneUserAccount } from "../../../components/Common/HelperComponents/CustomHooks";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const CostInsightCard = ({
  licenseName,
  yesterdayCost,
  monthToDateCost,
  yearToDateCost,
}) => {
  const isPaidLicense = !freeLicenses.includes(licenseName);
  const hasActiveAccounts = useHasActiveUserAccounts(); // Check if user has active accounts
  const isCostInsightEnabled = useIsCostInsightEnabledForOneUserAccount(); // Check if Cost Insight is enabled

  return (
    <div className={styles.costcard}>
      <div className={styles.cardheader}>Cost Insight</div>
      <div className={styles.cardGroupContainer}>
        {isPaidLicense ? (
          hasActiveAccounts ? (
            isCostInsightEnabled ? (
              <div className={styles.cardGroup}>
                <CardComponent
                  title="Yesterday's Spend"
                  amount={yesterdayCost}
                  color="#006fbb"
                />
                <CardComponent
                  title="Month to Date Spend"
                  amount={monthToDateCost}
                  color="#006fbb"
                />
                <CardComponent
                  title="Year to Date Spend"
                  amount={yearToDateCost}
                  color="#006fbb"
                />
              </div>
            ) : (
              <Alert variant="outlined" severity="info">
                Cost Insight is currently disabled. Enable this feature and gain
                full access to your cloud environment’s cost insight!
              </Alert>
            )
          ) : (
            <Alert variant="outlined" severity="warning">
              Please make sure you have access to at least one active account.
            </Alert>
          )
        ) : (
          <Alert variant="outlined" severity="info">
            Please subscribe to unlock this dashboard and gain full access to
            your cloud environment’s cost insight!
          </Alert>
        )}
      </div>
    </div>
  );
};

export default CostInsightCard;
