import React, { useMemo, useState } from "react";
import { Box, IconButton, Divider, Tooltip } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ComplianceChart from "./ComplianceChart";
import DashboardCard from "../components/DashboardCard";
import { useAccessibleUserAccounts } from "../../../components/Common/HelperComponents/CustomHooks";
import { useOrganizations } from "../../../components/Common/HelperComponents/GetDataFromStore";
import { useComplianceData } from "./useComplianceData";
import ApiLoading from "../components/ApiLoading";
import ApiError from "../components/ApiError";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const getColorForCompliance = (name) => {
  const colorMap = {
    AWS: "#2196f3", // Blue
    HITRUST: "#f44336", // Red
    SOC2: "#9e9e9e", // Gray
    GDPR: "#673ab7", // Deep Purple
    ISO: "#00bcd4", // Cyan
    NIST: "#009688", // Teal
    MITRE: "#795548", // Brown
    CCPA: "#e81e63", // Pink
    PCI: "#8bc34a", // Light Green
    CIS: "#ffc107", // Amber
    HIPAA: "#ff9800", // Orange
  };

  return colorMap[name] || "#000000"; // Default color if not found
};

const Compliance = () => {
  const history = useHistory();
  const accessibleAccounts = useAccessibleUserAccounts();
  const organizations = useOrganizations();

  const [sortOrder, setSortOrder] = useState("desc");

  const accCodes = useMemo(
    () => accessibleAccounts.map((account) => account.accountCode),
    [accessibleAccounts]
  );
  const orgCode = useMemo(() => organizations?.[0]?.orgcode, [organizations]);

  const { data, loading, error } = useComplianceData(accCodes, orgCode);

  const handleSort = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const sortedData = useMemo(() => {
    return data?.Compliance?.sort((a, b) =>
      sortOrder === "asc"
        ? a.Percentage - b.Percentage
        : b.Percentage - a.Percentage
    );
  }, [data, sortOrder]);

  const handleGoTo = () => {
    console.log("Go-to action clicked");
    history.push("/Compliance");
  };

  const actions = [
    {
      icon: (
        <IconButton onClick={handleSort} size="small" sx={{ p: 0, mx: -2.5 }}>
          <SortIcon fontSize="small" />
        </IconButton>
      ),
      label: "Sort",
    },
    {
      icon: (
        <Divider
          orientation="vertical"
          flexItem
          sx={{ alignSelf: "center", height: "18px", mx: -2 }} // Adjusted margin
        />
      ),
      label: "Divider",
    },
    {
      icon: (
        <Tooltip title="Open Detailed View">
          <IconButton onClick={handleGoTo} size="small" sx={{ p: 0 }}>
            <OpenInNewOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
      label: "Go-to",
    },
  ];

  return (
    <DashboardCard title="Compliance" actions={actions}>
      {loading ? (
        <ApiLoading parameter="Compliance" />
      ) : error ? (
        <ApiError parameter="Compliance" />
      ) : (
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            overflowY: "auto",
            pr: 1,
            mb: 1,
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "background.default",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "grey.400",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "grey.500",
            },
          }}
        >
          {sortedData?.map((item, index) => (
            <ComplianceChart
              key={index}
              name={item.Name}
              value={item.Percentage}
              color={getColorForCompliance(item.Name)}
            />
          ))}
        </Box>
      )}
    </DashboardCard>
  );
};

export default Compliance;
