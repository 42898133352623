import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

export const Findings = () => {
  const VIEW_URL = process.env.REACT_APP_GUARD_DUTY_FINDINGS_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const ProtectionPlan = () => {
  const VIEW_URL = process.env.REACT_APP_GUARD_DUTY_PROTECTION_PLAN_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};
