import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetInsightMetric } from "../../../store/actions"; // Update with actual action

export const useAssetInsightData = (accCodes, orgCode) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const assetInsightMetric = useSelector(
    (state) => state.metricInfo.assetInsightMetric
  );
  const assetInsightMetricLoading = useSelector(
    (state) => state.metricInfo.assetInsightMetricLoading
  );
  const assetInsightMetricError = useSelector(
    (state) => state.metricInfo.assetInsightMetricError
  );

  // To track if the API call has already been made
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (accCodes.length > 0 && orgCode && !hasFetchedData.current) {
      console.log("Making API call for asset insight data");
      dispatch(getAssetInsightMetric(accCodes, orgCode)); // Dispatch the action
      hasFetchedData.current = true; // Mark the call as done
    } else {
      console.log("Skipping API call for asset insight data");
    }
  }, [dispatch, accCodes, orgCode]);

  useEffect(() => {
    if (assetInsightMetric) {
      const metricObject = apiDataToAssetInsightMetricObject(assetInsightMetric);
      setData(metricObject);
    }
    setLoading(assetInsightMetricLoading);
    setError(assetInsightMetricError ? "Error in Asset Insight API" : null);
  }, [assetInsightMetric, assetInsightMetricLoading, assetInsightMetricError]);

  return { data, loading, error };
};

const apiDataToAssetInsightMetricObject = (apiData) => {
  const getSafeValue = (data, path) => {
    const keys = path.split('.');
    let value = data;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return 0;
      }
    }
    return value;
  };

  let metricObject = {};
  metricObject.ec2Running = getSafeValue(apiData, "Asset Management.0.EC2 Instances.Running");
  metricObject.ec2Stopped = getSafeValue(apiData, "Asset Management.0.EC2 Instances.Stopped");
  metricObject.ec2Total = getSafeValue(apiData, "Asset Management.0.EC2 Instances.Total");

  metricObject.snsTopicsTotal = getSafeValue(apiData, "Asset Management.1.SNS Topics.Total");
  metricObject.lambdaFunctionsTotal = getSafeValue(apiData, "Asset Management.2.Lambda Functions.Total");
  metricObject.eksClustersTotal = getSafeValue(apiData, "Asset Management.3.EKS Cluster.Total");
  metricObject.ecsClustersTotal = getSafeValue(apiData, "Asset Management.4.ECS Cluster.Total");
  metricObject.acmPublicCertificates = getSafeValue(apiData, "Asset Management.5.ACM Certificates.Public Certificates");
  metricObject.acmPrivateCertificates = getSafeValue(apiData, "Asset Management.5.ACM Certificates.Private Certificates");
  metricObject.acmCertificatesTotal = getSafeValue(apiData, "Asset Management.5.ACM Certificates.Total");
  metricObject.caTotal = getSafeValue(apiData, "Asset Management.6.Certificate Authority.Total");
  metricObject.dbTotal = getSafeValue(apiData, "Asset Management.7.Databases.Total");
  metricObject.dynamoDb = getSafeValue(apiData, "Asset Management.7.Databases.DynamoDB");
  metricObject.rds = getSafeValue(apiData, "Asset Management.7.Databases.RDS");
  metricObject.cloudTrailsTotal = getSafeValue(apiData, "Asset Management.8.CloudTrails.Total");
  metricObject.cloudWatchAlarmsTotal = getSafeValue(apiData, "Asset Management.9.CloudWatch Alarms.Total");

  metricObject.apiTotal = getSafeValue(apiData, "Asset Management.10.API Gateways.Total");
  metricObject.apiHttp = getSafeValue(apiData, "Asset Management.10.API Gateways.HTTP");
  metricObject.apiWebSocket = getSafeValue(apiData, "Asset Management.10.API Gateways.WebSocket");
  metricObject.apiRest = getSafeValue(apiData, "Asset Management.10.API Gateways.REST");

  metricObject.cloudFrontDistTotal = getSafeValue(apiData, "Asset Management.11.CloudFront Distributions.Total");
  metricObject.vpcTotal = getSafeValue(apiData, "Asset Management.12.VPCs.Total");
  metricObject.iamUsersTotal = getSafeValue(apiData, "Asset Management.13.IAM.IAM Users");
  metricObject.iamCustomerCreatedRoles = getSafeValue(apiData, "Asset Management.13.IAM.Customer Created Roles");
  metricObject.iamCustomerPolicies = getSafeValue(apiData, "Asset Management.13.IAM.Customer Policies");
  metricObject.efsTotal = getSafeValue(apiData, "Asset Management.14.Elastic File Systems.Total");
  metricObject.s3TotalBuckets = getSafeValue(apiData, "Asset Management.15.S3.Total Buckets");
  metricObject.s3PublicBuckets = getSafeValue(apiData, "Asset Management.15.S3.Public Buckets");
  metricObject.s3EncryptedBuckets = getSafeValue(apiData, "Asset Management.15.S3.Encrypted Buckets");
  metricObject.route53 = getSafeValue(apiData, "Asset Management.16.ROUTE53.Total");
  metricObject.kmsAwsManagedKeys = getSafeValue(apiData, "Asset Management.17.KMS.Aws Managed Keys");
  metricObject.kmsCustomerManagedKeys = getSafeValue(apiData, "Asset Management.17.KMS.Customer Managed Keys");
  metricObject.kmsTotalKeys = getSafeValue(apiData, "Asset Management.17.KMS.Total");
  metricObject.wafTotal = getSafeValue(apiData, "Asset Management.18.WAF.Total");
  metricObject.sqsTotal = getSafeValue(apiData, "Asset Management.19.SQS.Total");
  metricObject.secretManagerTotal = getSafeValue(apiData, "Asset Management.20.Secrets Manager.Total");
  metricObject.backupTotal = getSafeValue(apiData, "Asset Management.21.AWS Backup.Total");
  metricObject.awsBackupPlans = getSafeValue(apiData, "Asset Management.21.AWS Backup.Backup Plans");
  metricObject.awsBackupVaults = getSafeValue(apiData, "Asset Management.21.AWS Backup.Backup Vaults");
  metricObject.awsBackupProtectedResources = getSafeValue(apiData, "Asset Management.21.AWS Backup.Protected Resources");
  metricObject.ecrTotal = getSafeValue(apiData, "Asset Management.22.ECR.Total");
  metricObject.ecrPrivateResopitories = getSafeValue(apiData, "Asset Management.22.ECR.Ecr Private Repositories");
  metricObject.ecrPublicResopitories = getSafeValue(apiData, "Asset Management.22.ECR.Ecr Public Repositories");
  metricObject.elbTotal = getSafeValue(apiData, "Asset Management.23.Load Balancer.Total");
  metricObject.elbClassic = getSafeValue(apiData, "Asset Management.23.Load Balancer.Classic");
  metricObject.elbApplication = getSafeValue(apiData, "Asset Management.23.Load Balancer.Application");
  metricObject.elbGateway = getSafeValue(apiData, "Asset Management.23.Load Balancer.Gateway");
  metricObject.elbNetwork = getSafeValue(apiData, "Asset Management.23.Load Balancer.Network");
  metricObject.autoScalingTotal = getSafeValue(apiData, "Asset Management.24.Load Balancer.Total");
  metricObject.autoScalingGroup = getSafeValue(apiData, "Asset Management.24.Load Balancer.Auto Scaling Group");
  metricObject.autoScalingPlan = getSafeValue(apiData, "Asset Management.24.Load Balancer.Auto Scaling Plan");
  metricObject.guardDutyTotal = getSafeValue(apiData, "Asset Management.25.Guard Duty.Total");
  metricObject.guardDutyFindings = getSafeValue(apiData, "Asset Management.25.Guard Duty.Guard Duty Findings");
  metricObject.guardDutyProtectedS3Buckets = getSafeValue(apiData, "Asset Management.25.Guard Duty.Protected S3 buckets");
  metricObject.eventBridgeTotal = getSafeValue(apiData, "Asset Management.26.Event Bridge.Total");
  metricObject.eventBridgeRulesCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Rules Count");
  metricObject.eventBridgeEventBusesCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Event Buses Count");
  metricObject.eventBridgePipesCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Pipes Count");
  metricObject.eventBridgeScheduleCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Schedule Count");
  metricObject.systemsManagerManagedNodes = getSafeValue(apiData, "Asset Management.28.Systems Manager.Managed Nodes");
  metricObject.systemsManagerAppConfigProfiles = getSafeValue(apiData, "Asset Management.28.Systems Manager.Config Profiles");
  metricObject.systemsManagerApplications = getSafeValue(apiData, "Asset Management.28.Systems Manager.Applications");
  metricObject.systemsManagerTotal = getSafeValue(apiData, "Asset Management.28.Systems Manager.Total");

  return metricObject;
};
