import React from "react";
import Chart from "react-apexcharts";
import styles from "./landingpage.module.css";

const ComplianceInfoCard = ({ complianceData }) => {
  const generateColors = () => {
    return complianceData.Compliance.map((obj) => {
      if (obj.Name === "AWS") {
        return "#5f9ed1";
      }
      if (obj.Name === "HITRUST") {
        return "#7d4f50";
      }
      if (obj.Name === "CSA") {
        return "#499894";
      }  
      if (obj.Name === "MITRE") {
        return "#ababab";
      }          
      if (obj.Name === "CCPA") {
        return "#b07aa1";
      }
      if (obj.Name === "CIS") {
        return "#ffbc79";
      }
      if (obj.Name === "GDPR") {
        return "#595959";
      }
      if (obj.Name === "HIPAA") {
        return "#aa998f";
      }
      if (obj.Name === "ISO") {
        return "#a2c8ec";
      }
      if (obj.Name === "NIST") {
        return "#d1be9c";
      }
      if (obj.Name === "PCI") {
        return "#898989";
      }
      if (obj.Name === "SOC2") {
        return "#cfcfcf";
      }
      return "#FFFFFF"; // Default color
    });
  };
  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        colors: {
          backgroundBarColors: ["#FFFFFF"],
          backgroundBarOpacity: 0.5,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return `${val}%`;
      },
      style: {
        colors: ["#000000"], // Set the color to black (#000000)
      },
    },
    colors: generateColors(),
    xaxis: {
      labels: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + "%"; // Append the percentage symbol to the value
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };
  const complianceDataSeries = complianceData.Compliance.map((obj) => {
    return { x: obj.Name, y: parseFloat(obj.Percentage).toFixed(2) };
  });
  const series = [
    {
      data: complianceDataSeries,
    },
  ];

  return (
    <div className={styles.compliancecard}>
      <div className={styles.cardheader}>Compliance Scores</div>
      <Chart type="bar" options={chartOptions} series={series} height="100%" />
    </div>
  );
};

export default ComplianceInfoCard;
