import React, { useState } from "react";
import Chart from "react-apexcharts";
import styles from "./landingpage.module.css";
import ReactECharts from "echarts-for-react";

import Tooltip from "@mui/material/Tooltip";

const SecurityScoreCard = ({
  criticalValue,
  highValue,
  mediumValue,
  lowValue,
  securityScorePercentage,
  resourcePassedCount,
  resourceFailedCount,
}) => {
  const [showLegend, setShowLegend] = useState(false);
  const toggleLegend = () => setShowLegend(!showLegend);

  return (
    <div className={styles.securityscorecard}>
      <div className={styles.cardheader}>Cloud Security Posture Management</div>
      <div className={styles.securityCardsContainer}>
        <div className={styles.securityScoreCardContainer}>
          <div className={styles.securityCardTitle}>
            Security Score
            <Tooltip title="CSPM score is calculated based on distinct rules" placement="top-start">
              <i className="ri-information-line"></i>
            </Tooltip>
          </div>
          <RadialBarChart securityScore={securityScorePercentage} />
        </div>
        <div className={styles.securitySeverityCardContainer}>
          <div
            style={{
              color: "black",
              cursor: "pointer",
              fontWeight: "bold",
              fontSize: "small",
            }}
            onMouseEnter={() => setShowLegend(true)}
            onMouseLeave={() => setShowLegend(false)}
          >
            Severity
            <Tooltip title="Severity Level ranks the seriousness of security problems from 'Critical' (very serious) to 'Low' (not very serious). This helps prioritize which issues to fix first." placement="top">
              <i className="ri-information-line"></i>
            </Tooltip>
          </div>
          <SecurityLevelCard
            criticalValue={criticalValue}
            highValue={highValue}
            mediumValue={mediumValue}
            lowValue={lowValue}
            legend={showLegend}
          />
        </div>
      </div>
      <ResourcesCountCard
        passedCount={resourcePassedCount}
        failedCount={resourceFailedCount}
      />
    </div>
  );
};
export default SecurityScoreCard;

const RadialBarChart = ({ securityScore }) => {
  // Parse the securityScore to a number
  const parsedScore = parseFloat(securityScore);
  
  // Check if parsedScore is a valid number and within the acceptable range
  const isValidScore = !isNaN(parsedScore) && parsedScore >= 0 && parsedScore <= 100;

  const option = {
    series: [
      {
        type: "gauge",
        min: 0,
        max: 100,
        axisLine: {
          lineStyle: {
            width: 10,
            color: [
              [isValidScore ? parsedScore / 100 : 0, "green"], // Transition to green based on security score
              [1, "#FF0000"], // Remain red for the rest
            ],
          },
        },
        itemStyle: {
          color: "black",
        },
        pointer: {
          width: 4,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 10,
          lineStyle: {
            color: '#999'
          },
        },
        axisLabel: {
          distance: 2,
          color: "#999",
          fontSize: 8,
        },
        anchor: {
          show: true,
          showAbove: true,
          itemStyle: {
            borderWidth: 4,
          },
        },
        title: {
          show: false,
        },
        detail: {
          formatter: isValidScore ? "{value}%" : "0",
          valueAnimation: true,
          fontSize: 16,
          offsetCenter: [0, "70%"],
          color: "black",
        },
        data: [
          {
            value: isValidScore ? parsedScore : 0, // Use 0 if invalid, but it will display "N/A"
          },
        ],
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ minWidth: "100%", height: "100%" }}
    />
  );
};



const SecurityLevelCard = ({
  criticalValue,
  highValue,
  mediumValue,
  lowValue,
  legend,
}) => {
  const total = criticalValue + highValue + mediumValue + lowValue;
  const chartOptions = {
    labels: ["Critical", "High", "Medium", "Low"],
    legend: {
      position: "bottom",
      show: legend,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    fill: {
      // colors: ["#d82f06", "#f39011", "#f2d629", "#59be2f"],
      colors: ["#ff847c", "#fdb43b", "#ffed75", "#8effad"],
    },
    // colors: ["#d82f06", "#f39011", "#f2d629", "#59be2f"],
    colors: ["#ff847c", "#fdb43b", "#ffed75", "#8effad"],
    plotOptions: {
      pie: {
        customScale: 0.85,
        donut: {
          size: "75%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
            },
            total: {
              show: true,
              showAlways: true,
              // label: ${securityScorePercentage},
              formatter: function (val, opts) {
                return total;
              },
            },
          },
        },
      },
    },
  };

  return (
    <Chart
      options={chartOptions}
      series={[criticalValue, highValue, mediumValue, lowValue]}
      type="donut"
    />
  );
};

const ResourcesCountCard = ({ passedCount, failedCount }) => {
  const total = passedCount + failedCount;

  return (
    <div className={styles.resourceCountContainer}>
      <div className={styles.resourceCountHeader}>
        Resources
        <Tooltip title="Resources are the parts of the organization's IT setup, like computers, storage, and networks, that need to be kept safe from security threats." placement="top">
          <i className="ri-information-line"></i>
        </Tooltip>
      </div>
      <div className={styles.resourceCountCardContainer}>
        <Tooltip title="Total Resources on which Security Rules were applied" placement="top">
          <div
            className={`${styles.resourceCountCard} ${styles.resourceTotalCountCard}`}
          >
            Total: {total}
          </div>
        </Tooltip>
        <Tooltip title="Resources that passed Security Rules" placement="top">
          <div
            className={`${styles.resourceCountCard} ${styles.resourcePassedCountCard}`}
          >
            Passed: {passedCount}
          </div>
        </Tooltip>
        <Tooltip title="Resources that failed Security Rules" placement="top">
          <div
            className={`${styles.resourceCountCard} ${styles.resourceFailedCountCard}`}
          >
            Failed: {failedCount}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
