import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import TrendAnalyzer from "./TrendAnalyzer";
import ComplianceEmbed from "./ComplianceEmbed";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const Compliance = ({ licenseName, message }) => {
  const [activeTab, setActiveTab] = useState("compliance");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "compliance":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          return <ComplianceEmbed />;
        }
      case "trend-analyzer":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          return <TrendAnalyzer />;
        }
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Compliance" value="compliance" />
        <Tab label="Trend Analyzer" value="trend-analyzer" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default Compliance;
