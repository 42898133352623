import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

export const EventBuses = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_EVENTBUSES_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const Rules = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_RULES_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const GlobalEndpoints = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_GLOBAL_ENDPOINTS_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const Pipes = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_PIPES_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const Schedules = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_SCHEDULES_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const SchedulesGroups = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_SCHEDULES_GROUPS_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const APIDestinations = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_API_DESTINATIONS;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const Connections = () => {
  const VIEW_URL = process.env.REACT_APP_EVENTBRIDGE_CONNECTIONS;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};
