import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

const CloudWatch = () => {
  const VIEW_URL = process.env.REACT_APP_CLOUDWATCH;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>;
    </div>
  );
};

export default CloudWatch;
