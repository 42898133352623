import React, { useState, useEffect } from "react";
import EmbedShell from "../../components/Common/EmbedShell";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const ComplianceEmbed = ({ licenseName, message }) => {
  const VIEW_URL = process.env.REACT_APP_COMPLIANCE_VIEW;

  if (freeLicenses.includes(licenseName)) {
    return <InaccessibleResources message={message} />;
  }

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export default ComplianceEmbed;
