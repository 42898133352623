import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import MitreAttack from "./MitreAttack";
import CloudTrailEmbed from "./CloudTrailEmbed";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const EventInsight = ({ licenseName, message }) => {
  const [activeTab, setActiveTab] = useState("event-insight");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "event-insight":
        if(freeLicenses.includes(licenseName)) {
        return <InaccessibleResources message={message} />
        } else {
          return <GetKibanaDashboard dashboardKey={'Event_Insight_New'} />;
        }
      case "mitre-attack":
        if(freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />
          } else {
            return <GetKibanaDashboard dashboardKey={'MItre_event Insight'} />;
          }        
      default:
        return null;
    }
  };

  return (
    <div style={{"marginTop": "5rem"}}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Event Insight" value="event-insight" />
        <Tab label="Mitre Attack" value="mitre-attack" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default EventInsight;
