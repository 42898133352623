import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

const RuleGroups = () => {
  const VIEW_URL = process.env.REACT_APP_WAF_RULE_GROUPS;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export default RuleGroups;
