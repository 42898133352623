import React from "react";
import { Box, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell } from "recharts";

const ComplianceChart = ({ name, value, color }) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box sx={{ flexGrow: 1, ml: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={0.5}>
          <Typography
            variant="body2"
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
          >
            {value}%
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height={12}> {/* Adjusted bar height */}
          <BarChart
            data={[{ name, value, color }]}
            layout="vertical"
            barSize={12}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            maxBarSize={100} // Set the max bar size to represent 100%
          >
            <XAxis type="number" domain={[0, 100]} hide /> {/* Ensure the domain is 0-100 */}
            <YAxis type="category" dataKey="name" hide />
            <Bar dataKey="value" radius={[10, 10, 10, 10]}>
              <Cell fill={color} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default ComplianceChart;
