import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

export const PrivateEcr = () => {
  const VIEW_URL = process.env.REACT_APP_PRIVATE_ECR_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const PublicEcr = () => {
    const VIEW_URL = process.env.REACT_APP_PUBLIC_ECR_VIEW;
  
    return (
      <div style={{ marginTop: "-4rem" }}>
        <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
      </div>
    );
  };