export const sidebarMenuOptions = (t, handleRedirectLink, profile) => {
  return [
    {
      id: 17,
      link: "/dashboard",
      clickHandler: handleRedirectLink,
      group: "Dashboard",
      classNames: "waves-effect",
      iconClassName: "ri-dashboard-fill",
      displayName: t("Dashboard"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 0,
      link: "/assetinsight",
      clickHandler: handleRedirectLink,
      group: "ApplicationIntegration",
      classNames: "waves-effect",
      iconClassName: "ri-file-list-3-fill",
      displayName: t("Asset Insight"),
      isVisible: true,
      expand: false,
      isActive: false,
      subMenu: [
        {
          id: 1,
          link: "/ApplicationIntegration",
          clickHandler: handleRedirectLink,
          group: "ApplicationIntegration",
          classNames: "waves-effect",
          iconClassName: "ri-apps-2-fill",
          displayName: t("App Integration"),
          isVisible: true,
          isSubmenu: true,
          expand: false,
          isActive: false,
          subMenu: [
            {
              id: 11, //31
              link: "/assetinsight/SNS",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_SNS_VIEW },
              displayName: t("SNS"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 12, //31
              link: "/assetinsight/SQS",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_SQS_VIEW },
              displayName: t("SQS"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 12, //31
              link: "/assetinsight/EventBridge",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              // params: { embedShellUrl: process.env.REACT_APP_SQS_VIEW },
              displayName: t("EventBridge"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 2,
          link: "/Compute",
          clickHandler: handleRedirectLink,
          group: "Compute",
          classNames: "waves-effect",
          iconClassName: "ri-terminal-box-fill",
          displayName: t("Compute"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 21,
              link: "/assetinsight/EC2",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-stack-fill",
              params: { embedShellUrl: process.env.REACT_APP_EC2_VIEW },
              displayName: t("EC2"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 22, //31
              link: "/assetinsight/Lambda",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_LAMBDA_VIEW },
              displayName: t("Lambda"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 3,
          link: "/Containers",
          clickHandler: handleRedirectLink,
          group: "Containers",
          classNames: "waves-effect",
          iconClassName: "ri-safe-2-fill",
          displayName: t("Containers"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 31,
              link: "/assetinsight/EKS",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",
              params: { embedShellUrl: process.env.REACT_APP_EKS_VIEW },
              displayName: t("EKS"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 32,
              link: "/assetinsight/ECS",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",
              params: { embedShellUrl: process.env.REACT_APP_ECS_VIEW },
              displayName: t("ECS"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 33,
              link: "/assetinsight/ECR",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",
              params: { embedShellUrl: process.env.REACT_APP_ECR_VIEW },
              displayName: t("ECR"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 4,
          link: "/CryptographyPKI",
          clickHandler: handleRedirectLink,
          group: "CryptographyPKI",
          classNames: "waves-effect",
          iconClassName: "ri-key-fill",
          displayName: t("Cryptography"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 41,
              link: "/assetinsight/ACM",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_ACM_VIEW },
              displayName: t("ACM"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 42,
              link: "/assetinsight/KMS",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_KMS_VIEW },
              displayName: t("KMS"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 5,
          link: "/Database",
          clickHandler: handleRedirectLink,
          group: "Database",
          classNames: "waves-effect",
          iconClassName: "ri-database-2-fill",
          displayName: t("Database"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 51,
              link: "/assetinsight/DynamoDB",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_DYNAMODB_VIEW },
              displayName: t("DynamoDB"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 52,
              link: "/assetinsight/RDS",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              displayName: t("RDS"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 6,
          link: "/MgntGovernance",
          clickHandler: handleRedirectLink,
          group: "Management&Governance",
          classNames: "waves-effect",
          iconClassName: "ri-government-fill",
          displayName: t("Management & Governance"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 61, //32
              link: "/assetinsight/CloudTrail",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: {
                embedShellUrl:
                  process.env.REACT_APP_CLOUDTRAIL_assetinsight_VIEW,
              },
              displayName: t("CloudTrail"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 62, //32
              link: "/assetinsight/CloudWatch",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_CLOUDWATCH },
              displayName: t("CloudWatch"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 63,
              link: "/assetinsight/AutoScaling",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              // params: { embedShellUrl: process.env.REACT_APP_AUTOSCALING_VIEW },
              displayName: t("Auto Scaling"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 63,
              link: "/assetinsight/SystemsManager",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              displayName: t("Systems Manager"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 7,
          link: "/Networking",
          clickHandler: handleRedirectLink,
          group: "Networking",
          classNames: "waves-effect",
          iconClassName: "ri-arrow-up-down-line",
          displayName: t("Networking"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 71, //31
              link: "/assetinsight/APIGateway",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_APIGATEWAY_VIEW },
              displayName: t("API Gateway"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 72, //31
              link: "/assetinsight/CloudFront",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_CF_VIEW },
              displayName: t("CloudFront"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 73,
              link: "/assetinsight/VPC",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "fas fa-archway",
              params: { embedShellUrl: process.env.REACT_APP_VPC_VIEW },
              displayName: t("VPC"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 74,
              link: "/assetinsight/Route53",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "fas fa-archway",
              params: { embedShellUrl: process.env.REACT_APP_ROUTE53_VIEW },
              displayName: t("Route 53"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 75,
              link: "/assetinsight/WAF",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shield-fill",
              params: { embedShellUrl: process.env.REACT_APP_WAF_WEB_ACL },
              displayName: t("WAF"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 8,
          link: "/IdentityCompliance",
          clickHandler: handleRedirectLink,
          group: "Identity&Compliance",
          classNames: "waves-effect",
          iconClassName: "ri-file-shield-2-fill",
          displayName: t("Identity & Compliance"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 81,
              link: "/assetinsight/IAM",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",
              params: { embedShellUrl: process.env.REACT_APP_IAM_VIEW },
              displayName: t("IAM"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 81,
              link: "/assetinsight/SecretsManager",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",
              params: {
                embedShellUrl: process.env.REACT_APP_SECRETMANAGER_VIEW,
              },
              displayName: t("Secrets Manager"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              // id: 81,
              link: "/assetinsight/GuardDuty",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",

              displayName: t("GuardDuty"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
        {
          id: 9,
          link: "/Storage",
          clickHandler: handleRedirectLink,
          group: "Storage",
          classNames: "waves-effect",
          iconClassName: "ri-folder-5-fill",
          displayName: t("Storage"),
          isVisible: true,
          expand: false,
          isSubmenu: true,
          isActive: false,
          subMenu: [
            {
              id: 91,
              link: "/assetinsight/S3",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-shopping-basket-fill",
              params: { embedShellUrl: process.env.REACT_APP_S3_VIEW },
              displayName: t("S3"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 92,
              link: "/assetinsight/EFS",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",
              params: { embedShellUrl: process.env.REACT_APP_EFS_VIEW },
              displayName: t("EFS"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
            {
              id: 93,
              link: "/assetinsight/Backup",
              clickHandler: handleRedirectLink,
              classNames: "waves-effect",
              // iconClassName: "ri-key-fill",
              params: { embedShellUrl: process.env.REACT_APP_BACKUP_JOBS_VIEW },
              displayName: t("Backup"),
              isVisible: true,
              isSubmenu: true,
              isActive: false,
            },
          ],
        },
      ],
    },
    {
      id: 14,
      link: "/costinsight",
      group: "costInsight",
      clickHandler: handleRedirectLink,
      classNames: "waves-effect",
      iconClassName: "ri-money-dollar-circle-fill",
      displayName: t("Cost Insight"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 15,
      link: "/costoptimization",
      group: "costOptimization",
      clickHandler: handleRedirectLink,
      classNames: "waves-effect",
      iconClassName: "ri-money-dollar-circle-fill",
      displayName: t("Cost Optimization"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 11,
      link: "/eventinsight",
      clickHandler: handleRedirectLink,
      group: "eventInsight",
      classNames: "waves-effect",
      iconClassName: "ri-pie-chart-fill",
      displayName: t("Event Insight"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 10,
      link: "/Security",
      clickHandler: handleRedirectLink,
      group: "Security",
      classNames: "waves-effect",
      iconClassName: "ri-shield-keyhole-line",
      displayName: t("Security Posture"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 12,
      link: "/Compliance",
      clickHandler: handleRedirectLink,
      classNames: "waves-effect",
      group: "Compliance",
      iconClassName: "ri-todo-line",
      displayName: t("Compliance"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 17,
      link: "/iacmanagement/dashboard",
      clickHandler: handleRedirectLink,
      group: "IACManagement",
      classNames: "waves-effect",
      iconClassName: "ri-command-fill",
      displayName: t("IaC Scanning"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 16,
      link: "/configuration/accounts",
      clickHandler: handleRedirectLink,
      group: "configuration",
      classNames: "waves-effect",
      iconClassName: "ri-settings-4-line",
      displayName: t("Configuration"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 18,
      link: "/supportrequest",
      clickHandler: handleRedirectLink,
      group: "Support",
      classNames: "waves-effect",
      iconClassName: "ri-apps-2-fill",
      displayName: t("Support"),
      isVisible: true,
      expand: false,
      isActive: false,
      subMenu: [
        {
          id: 181,
          link: "/supportrequest",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-mail-send-line",
          displayName: t("Create Support Case"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
        {
          id: 182,
          link: "/releasenotes",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-file-list-3-fill",
          displayName: t("Release Notes"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
        {
          id: 183,
          link: "/user-manual",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-file-list-3-fill",
          displayName: t("User Manual"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
        {
          id: 183,
          link: "/faq",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-file-list-3-fill",
          displayName: t("FAQ"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
      ],
    },
  ];
};
