import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import accessToken from "../../helpers/jwt-token-access/accessToken";
import { setupInterceptors } from "../../helpers/api_helper";
import { getListOfOrgsAndAccts } from "./../../store/actions";
import jwt_decode from "jwt-decode";
import * as Loader from 'react-loader-spinner';

const MitreAttack = (props) =>{

    const VIEW_URL =  process.env.REACT_APP_ELASTICSERACH === undefined 
                        ? 'https://ao34ji8jhf.execute-api.us-east-1.amazonaws.com' 
                        : process.env.REACT_APP_ELASTICSERACH;

    const URL_SUFFIX = process.env.REACT_APP_MITRE_ATTACK_URL_SUFFIX;

    const LOCAL_STORAGE_AUTH_USER_PROPERTY_NAME = process.env.REACT_APP_MITRE_ATTACK_LOCAL_STORAGE_AUTH_USER_PROPERTY_NAME;
 
    const [dashboardEmbeded, setDashboardEmbeded] = useState(false);
    const [src, setSrc] = useState("");

    const history = useHistory();
    const token = accessToken();
    let accountNumbers = [];
    let orgCode = "";
    let username;
 
    let getListOfOrgsAndAcctsFun = props.getListOfOrgsAndAccts;
 
    if (localStorage.getItem(LOCAL_STORAGE_AUTH_USER_PROPERTY_NAME)) {
        const obj = JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_USER_PROPERTY_NAME));
        const authUser = jwt_decode(obj.token);
        username = authUser.sub.charAt(0) + authUser.sub.slice(1);
    }

    if (props.organizations && props.organizations.length >= 1) {
        orgCode = props.organizations[0].orgcode;
    }

    if(props.accounts) {
        props.accounts.map((account) => accountNumbers.push(account.accountNumber));
        accountNumbers = accountNumbers && accountNumbers.filter(accountNumber => !accountNumber.includes('INIT') && !accountNumber.includes('TEST') && accountNumber.length == 12)
    }
   
    const embedEventData = () => {
        if (accountNumbers.length > 0 && !dashboardEmbeded) {
            const ORG_CODE = orgCode.toLocaleLowerCase();
            const DASHBOARD_ID = process.env.REACT_APP_MITRE_ATTACK_DASHBOARD_ID;
            const SPACE_URL = process.env.REACT_APP_MITRE_ATTACK_SPACE_URL; 
            const VIEW_DASHBOARD_URL = process.env.REACT_APP_MITRE_ATTACK_VIEW_DASHBOARD_URL + DASHBOARD_ID;
            const QUERY_PARAMS = "?authorization=" + token;
            setSrc(VIEW_URL + SPACE_URL + QUERY_PARAMS + VIEW_DASHBOARD_URL + URL_SUFFIX);
            setDashboardEmbeded(true);
        }
    }

    const hideSpinner = () => {
        setTimeout(() => {
            document.getElementById("tableauDivLoading").style.display = "none";
            var eventDataIframe = document.getElementById("elasticsearchid");
            eventDataIframe.style.display = "block";
        }, 6000);
    }

    embedEventData();
   
    useEffect(() => {
        setDashboardEmbeded(false);
        async function getAccounts() {
            await getListOfOrgsAndAcctsFun();
        }
        getAccounts();       
    }, []);

    useEffect(() => {
        setupInterceptors(history);
    
    }, [history]);
 
    if (props.accounts && accountNumbers.length === 0) {
        return (
            <div>
                <div  className="tableauDivLoading" id="tableauDivLoading">
                    <h5>
                        No Data to Display, Because you have zero Cloud Accounts.
                    </h5>
                </div>
            </div>
        );
    } else if (!dashboardEmbeded ) {
        return (
            <div className = "tableauDivLoading" id = "tableauDivLoading" >
                <Loader.Rings color = "#00BFFF" height = {200} width = {200} />
            </div>
        );
    } else {
     
        return(
            <div>
                <div className = "tableauDivLoading" id = "tableauDivLoading" >
                            <Loader.Rings color = "#00BFFF" height = {200} width = {200} />
                        </div>
                <div className="box" id="tableauDiv" style={{"marginTop":"0px"}}>
                    <div className="iframe-container">
                        <iframe src = {src} className="iframe-second-row"  id="elasticsearchid" onLoad={hideSpinner}></iframe>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStatetoProps = (state) => {
    const { accounts, organizations } = state.orgAccount;
    return { accounts, organizations };
};
  
const mapDispatchToProps = (dispatch) => {
    return {
        getListOfOrgsAndAccts: () => {
            dispatch(getListOfOrgsAndAccts());
        },
    };
};

export default connect(mapStatetoProps, mapDispatchToProps)(MitreAttack)