var _ = require("lodash");

const apiDataToAssetInsightMetricObject = (apiData) => {
  const getSafeValue = (data, path) => {
    const keys = path.split('.');
    let value = data;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return 0;
      }
    }
    return value;
  };

  let metricObject = {};
  metricObject.ec2Running = getSafeValue(apiData, "Asset Management.0.EC2 Instances.Running");
  metricObject.ec2Stopped = getSafeValue(apiData, "Asset Management.0.EC2 Instances.Stopped");
  metricObject.ec2Total = getSafeValue(apiData, "Asset Management.0.EC2 Instances.Total");

  metricObject.snsTopicsTotal = getSafeValue(apiData, "Asset Management.1.SNS Topics.Total");
  metricObject.lambdaFunctionsTotal = getSafeValue(apiData, "Asset Management.2.Lambda Functions.Total");
  metricObject.eksClustersTotal = getSafeValue(apiData, "Asset Management.3.EKS Cluster.Total");
  metricObject.ecsClustersTotal = getSafeValue(apiData, "Asset Management.4.ECS Cluster.Total");
  metricObject.acmPublicCertificates = getSafeValue(apiData, "Asset Management.5.ACM Certificates.Public Certificates");
  metricObject.acmPrivateCertificates = getSafeValue(apiData, "Asset Management.5.ACM Certificates.Private Certificates");
  metricObject.acmCertificatesTotal = getSafeValue(apiData, "Asset Management.5.ACM Certificates.Total");
  metricObject.caTotal = getSafeValue(apiData, "Asset Management.6.Certificate Authority.Total");
  metricObject.dbTotal = getSafeValue(apiData, "Asset Management.7.Databases.Total");
  metricObject.dynamoDb = getSafeValue(apiData, "Asset Management.7.Databases.DynamoDB");
  metricObject.rds = getSafeValue(apiData, "Asset Management.7.Databases.RDS");
  metricObject.cloudTrailsTotal = getSafeValue(apiData, "Asset Management.8.CloudTrails.Total");
  metricObject.cloudWatchAlarmsTotal = getSafeValue(apiData, "Asset Management.9.CloudWatch Alarms.Total");

  metricObject.apiTotal = getSafeValue(apiData, "Asset Management.10.API Gateways.Total");
  metricObject.apiHttp = getSafeValue(apiData, "Asset Management.10.API Gateways.HTTP");
  metricObject.apiWebSocket = getSafeValue(apiData, "Asset Management.10.API Gateways.WebSocket");
  metricObject.apiRest = getSafeValue(apiData, "Asset Management.10.API Gateways.REST");

  metricObject.cloudFrontDistTotal = getSafeValue(apiData, "Asset Management.11.CloudFront Distributions.Total");
  metricObject.vpcTotal = getSafeValue(apiData, "Asset Management.12.VPCs.Total");
  metricObject.iamUsersTotal = getSafeValue(apiData, "Asset Management.13.IAM.IAM Users");
  metricObject.iamCustomerCreatedRoles = getSafeValue(apiData, "Asset Management.13.IAM.Customer Created Roles");
  metricObject.iamCustomerPolicies = getSafeValue(apiData, "Asset Management.13.IAM.Customer Policies");
  metricObject.efsTotal = getSafeValue(apiData, "Asset Management.14.Elastic File Systems.Total");
  metricObject.s3TotalBuckets = getSafeValue(apiData, "Asset Management.15.S3.Total Buckets");
  metricObject.s3PublicBuckets = getSafeValue(apiData, "Asset Management.15.S3.Public Buckets");
  metricObject.s3EncryptedBuckets = getSafeValue(apiData, "Asset Management.15.S3.Encrypted Buckets");
  metricObject.route53 = getSafeValue(apiData, "Asset Management.16.ROUTE53.Total");
  metricObject.kmsAwsManagedKeys = getSafeValue(apiData, "Asset Management.17.KMS.Aws Managed Keys");
  metricObject.kmsCustomerManagedKeys = getSafeValue(apiData, "Asset Management.17.KMS.Customer Managed Keys");
  metricObject.kmsTotalKeys = getSafeValue(apiData, "Asset Management.17.KMS.Total");
  metricObject.wafTotal = getSafeValue(apiData, "Asset Management.18.WAF.Total");
  metricObject.sqsTotal = getSafeValue(apiData, "Asset Management.19.SQS.Total");
  metricObject.secretManagerTotal = getSafeValue(apiData, "Asset Management.20.Secrets Manager.Total");
  metricObject.backupTotal = getSafeValue(apiData, "Asset Management.21.AWS Backup.Total");
  metricObject.awsBackupPlans = getSafeValue(apiData, "Asset Management.21.AWS Backup.Backup Plans");
  metricObject.awsBackupVaults = getSafeValue(apiData, "Asset Management.21.AWS Backup.Backup Vaults");
  metricObject.awsBackupProtectedResources = getSafeValue(apiData, "Asset Management.21.AWS Backup.Protected Resources");
  metricObject.ecrTotal = getSafeValue(apiData, "Asset Management.22.ECR.Total");
  metricObject.ecrPrivateResopitories = getSafeValue(apiData, "Asset Management.22.ECR.Ecr Private Repositories");
  metricObject.ecrPublicResopitories = getSafeValue(apiData, "Asset Management.22.ECR.Ecr Public Repositories");
  metricObject.elbTotal = getSafeValue(apiData, "Asset Management.23.Load Balancer.Total");
  metricObject.elbClassic = getSafeValue(apiData, "Asset Management.23.Load Balancer.Classic");
  metricObject.elbApplication = getSafeValue(apiData, "Asset Management.23.Load Balancer.Application");
  metricObject.elbGateway = getSafeValue(apiData, "Asset Management.23.Load Balancer.Gateway");
  metricObject.elbNetwork = getSafeValue(apiData, "Asset Management.23.Load Balancer.Network");
  metricObject.autoScalingTotal = getSafeValue(apiData, "Asset Management.24.Load Balancer.Total");
  metricObject.autoScalingGroup = getSafeValue(apiData, "Asset Management.24.Load Balancer.Auto Scaling Group");
  metricObject.autoScalingPlan = getSafeValue(apiData, "Asset Management.24.Load Balancer.Auto Scaling Plan");
  metricObject.guardDutyTotal = getSafeValue(apiData, "Asset Management.25.Guard Duty.Total");
  metricObject.guardDutyFindings = getSafeValue(apiData, "Asset Management.25.Guard Duty.Guard Duty Findings");
  metricObject.guardDutyProtectedS3Buckets = getSafeValue(apiData, "Asset Management.25.Guard Duty.Protected S3 buckets");
  metricObject.eventBridgeTotal = getSafeValue(apiData, "Asset Management.26.Event Bridge.Total");
  metricObject.eventBridgeRulesCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Rules Count");
  metricObject.eventBridgeEventBusesCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Event Buses Count");
  metricObject.eventBridgePipesCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Pipes Count");
  metricObject.eventBridgeScheduleCount = getSafeValue(apiData, "Asset Management.26.Event Bridge.Schedule Count");
  metricObject.systemsManagerManagedNodes = getSafeValue(apiData, "Asset Management.28.Systems Manager.Managed Nodes");
  metricObject.systemsManagerAppConfigProfiles = getSafeValue(apiData, "Asset Management.28.Systems Manager.Config Profiles");
  metricObject.systemsManagerApplications = getSafeValue(apiData, "Asset Management.28.Systems Manager.Applications");
  metricObject.systemsManagerTotal = getSafeValue(apiData, "Asset Management.28.Systems Manager.Total");

  return metricObject;
};


const apiDataToComplianceMetricObject = (apiData) => {
  let metricObject = {};
  metricObject.complianceHipaaPercent =
    apiData["Compliance"][0]["HIPAA"]["Percentage"];
  metricObject.compliancePciDssPercent =
    apiData["Compliance"][1]["PCI DSS"]["Percentage"];
  metricObject.complianceCisPercent =
    apiData["Compliance"][2]["CIS Benchmark"]["Percentage"];

  // console.log(
  //   "In apiDataToComplianceMetricObject(), metricObject = ",
  //   metricObject
  // );

  return metricObject;
};

const apiDataToDynamicComplianceMetricObject = (apiData) => {
  // apiData = JSON.parse(apiData);
  console.log("Type of api Data ::: ", typeof apiData);
  console.log(
    "In apiDataToComplianceMetricObject(), apiData[Compliance] = ",
    apiData
  );

  let metricObject = [];

  const complianceData = Object.values(apiData).filter(
    (item) => item.complianceRef !== undefined && item.AvgFixed !== undefined
  );

  const transformedData = {
    Compliance: complianceData.map((item) => ({
      Percentage: item.AvgFixed.toFixed(5),
      Name: item.complianceRef,
    })),
  };

  console.log(
    "In apiDataToComplianceMetricObject(), metricObject = ",
    transformedData
  );

  metricObject = transformedData;

  // console.log(
  //   "In apiDataToComplianceMetricObject(), metricObject = ",
  //   metricObject
  // );

  return metricObject;
};

const apiDataToCostInsightMetricObject = (apiData) => {
  console.log("In apiDataToCostInsightMetricObject(), apiData = ", apiData);
  let metricObject = {};

  metricObject.costInsightYesterdayCost = apiData["Yesterday Cost"];
  metricObject.costInsightMonthToDateCost = apiData["Month to Date Cost"];
  metricObject.costInsightYearToDateCost = apiData["Year to Date Cost"];
  metricObject.costInsightMonthlySavingsCost = apiData["Monthly Savings"];

  console.log(
    "In apiDataToCostInsightMetricObject(), metricObject = ",
    metricObject
  );

  return metricObject;
};

const apiDataToSecurityPostureMetricObject = (apiData) => {
  console.log("In apiDataToSecurityPostureMetricObject(), apiData = ", apiData);
  let metricObject = {};

  metricObject.securityPostSecPercent = apiData["AvgFixed"]["Failed"];
  metricObject.securityPostCriticalTotal =
    typeof apiData["critical"]["Failed"] === "string"
      ? parseInt(apiData["critical"]["Failed"])
      : apiData["critical"]["Failed"];
  metricObject.securityPostHighTotal =
    typeof apiData["high"]["Failed"] === "string"
      ? parseInt(apiData["high"]["Failed"])
      : apiData["high"]["Failed"];
  metricObject.securityPostModerateTotal =
    typeof apiData["moderate"]["Failed"] === "string"
      ? parseInt(apiData["moderate"]["Failed"])
      : apiData["moderate"]["Failed"];
  metricObject.securityPostLowTotal =
    typeof apiData["low"]["Failed"] === "string"
      ? parseInt(apiData["low"]["Failed"])
      : apiData["low"]["Failed"];

  metricObject.resourcePassedCount =
    typeof apiData["DistinctCountArnLocator"]["Passed"] === "string"
      ? parseInt(apiData["DistinctCountArnLocator"]["Passed"])
      : apiData["DistinctCountArnLocator"]["Passed"];
  metricObject.resourceFailedCount =
    typeof apiData["DistinctCountArnLocator"]["Failed"] === "string"
      ? parseInt(apiData["DistinctCountArnLocator"]["Failed"])
      : apiData["DistinctCountArnLocator"]["Failed"];
  console.log(
    "In apiDataToSecurityPostureMetricObject(), metricObject = ",
    metricObject
  );

  return metricObject;
};

const apiDataToEventDataMetricObject = (apiData) => {
  // console.log("In apiDataToEventDataMetricObject, apiData = ", apiData);
  let metricObject = {};

  metricObject.failedConsoleLoginsTotal =
    apiData["Event Data"][0]["Failed Console Logins"]["Total"];
  metricObject.failedAccessKeyLoginsTotal =
    apiData["Event Data"][1]["Failed AccessKey Logins"]["Total"];

  let eventSourceObject =
    apiData["Event Data"][2]["Top 5 Event Source"]["Event Source"];
  if (_.isEmpty(eventSourceObject)) {
    metricObject.topFiveEventSources = [];
  } else {
    metricObject.topFiveEventSources = Object.entries(eventSourceObject);
  }

  metricObject.numberOfEventsTotal =
    apiData["Event Data"][3]["Number of Events"]["Total Events"];

  let faileLoginObject =
    apiData["Event Data"][4]["Top 10 Failed Console Login IP Address"][
      "Ip Address"
    ];
  if (_.isEmpty(faileLoginObject)) {
    metricObject.topTenFailedConsoleLoginsIp = [];
  } else {
    metricObject.topTenFailedConsoleLoginsIp = Object.entries(faileLoginObject);
  }

  // console.log(
  //   "In apiDataToEventDataMetricObject(), metricObject = ",
  //   metricObject
  // );

  return metricObject;
};

export {
  apiDataToAssetInsightMetricObject,
  apiDataToComplianceMetricObject,
  apiDataToDynamicComplianceMetricObject,
  apiDataToCostInsightMetricObject,
  apiDataToSecurityPostureMetricObject,
  apiDataToEventDataMetricObject,
};
