import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Findings, ProtectionPlan } from "./GuardDutyDashboards";

const GuardDuty = () => {
  const [activeTab, setActiveTab] = useState("Findings");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Findings":
        return <Findings />;
      case "ProtectionPlan":
        return <ProtectionPlan />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Findings" value="Findings" />
        <Tab label="Protection Plan" value="ProtectionPlan" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default GuardDuty;
