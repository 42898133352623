import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { BackupJobs, BackupPlan, BackupVault, Framework, LegalHold, ProtectedResources, Reports } from "./BackupDashboards";

const BackupDashboard = () => {
  const [activeTab, setActiveTab] = useState("BackupJobs");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "BackupJobs":
        return <BackupJobs />;
      case "BackupPlan":
        return <BackupPlan />;
      case "BackupVault":
        return <BackupVault />;
      case "Framework":
        return <Framework />;
      case "LegalHold":
        return <LegalHold />;
      case "ProtectedResources":
        return <ProtectedResources />;
      case "Reports":
        return <Reports />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Backup Jobs" value="BackupJobs" />
        <Tab label="Backup Plan" value="BackupPlan" />
        <Tab label="Backup Vault" value="BackupVault" />
        <Tab label="Framework" value="Framework" />
        <Tab label="Legal Hold" value="LegalHold" />
        <Tab label="Protected resources" value="ProtectedResources" />
        <Tab label="Reports" value="Reports" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default BackupDashboard;
