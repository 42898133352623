import React from "react";
import EmbedShell from "../../../components/Common/EmbedShell";

export const ReservedInstances = () => {
  const VIEW_URL = process.env.REACT_APP_RDS_RESERVED_INSTANCES_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export const RDS = () => {
  const VIEW_URL = process.env.REACT_APP_RDS_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};
