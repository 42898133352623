import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import LoadBalancer from "./LoadBalancer";
import TargetGroup from "./TargetGroup";
import EC2 from "./EC2";
import EBS from "./EBS";
import EbsSnapshot from "./EbsSnapshot";
import ClassicLoadBalancer from "./ClassicLoadBalancer";
import SavingsPlan from "./SavingPlans";
import ReservedInstances from "./ReservedInstances";

const EC2Dashboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get("tab") || "EC2";

  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "EC2":
        return <EC2 />;
      case "EBS":
        return <EBS />;
      case "EBS_SNAPSHOT":
        return <EbsSnapshot />;
      case "LoadBalancer":
        return <LoadBalancer />;
      case "classicLoadBalancer":
        return <ClassicLoadBalancer />;
      case "TargetGroup":
        return <TargetGroup />;
      case "SavingsPlan":
        return <SavingsPlan />;
      case "ReservedInstances":
        return <ReservedInstances />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable" 
        scrollButtons="auto"
      >
        <Tab label="EC2" value="EC2" />
        <Tab label="EBS" value="EBS" />
        <Tab label="EBS SNAPSHOT" value="EBS_SNAPSHOT" />
        <Tab label="Load Balancer" value="LoadBalancer" />
        <Tab label="Classic Load Balancer" value="classicLoadBalancer" />
        <Tab label="Target Group" value="TargetGroup" />
        <Tab label="Savings Plan" value="SavingsPlan" />
        <Tab label="Reserved Instances" value="ReservedInstances" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default EC2Dashboard;
