import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import {
  APIDestinations,
  Connections,
  EventBuses,
  GlobalEndpoints,
  Pipes,
  Rules,
  Schedules,
  SchedulesGroups,
} from "./EventBridgeDashboards";

const EventBridgeDashboards = () => {
  const [activeTab, setActiveTab] = useState("EventBuses");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "EventBuses":
        return <EventBuses />;
      case "Rules":
        return <Rules />;
      case "GlobalEndpoints":
        return <GlobalEndpoints />;
      case "Pipes":
        return <Pipes />;
      case "Schedules":
        return <Schedules />;
      case "SchedulesGroups":
        return <SchedulesGroups />;
      case "APIDestinations":
        return <APIDestinations />;
      case "Connections":
        return <Connections />;

      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Event Buses" value="EventBuses" />
        <Tab label="Rules" value="Rules" />
        <Tab label="Global Endpoints" value="GlobalEndpoints" />
        <Tab label="Pipes" value="Pipes" />
        <Tab label="Schedules" value="Schedules" />
        <Tab label="Schedule Groups" value="SchedulesGroups" />
        <Tab label="API Destinations" value="APIDestinations" />
        <Tab label="Connections" value="Connections" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default EventBridgeDashboards;
