import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SecurityScoreChart from "./SecurityScoreChart";
import DashboardCard from "../components/DashboardCard";
import ApiLoading from "../components/ApiLoading";
import { useSecurityScoreData } from "./useSecurityScoreData";
import { useAccessibleUserAccounts } from "../../../components/Common/HelperComponents/CustomHooks";
import { useOrganizations } from "../../../components/Common/HelperComponents/GetDataFromStore";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const SecurityScore = () => {
  const history = useHistory();

  const [showMore, setShowMore] = useState(false);

  const accessibleAccounts = useAccessibleUserAccounts();
  const organizations = useOrganizations();

  const accCodes = accessibleAccounts.map((account) => account.accountCode);
  const orgCode = organizations?.[0]?.orgcode;

  const { data, loading, error } = useSecurityScoreData(accCodes, orgCode);
  console.log("🚀 ~ SecurityScore ~ data:", data);

  if (loading) return <ApiLoading variant="card" height={150} width="100%" />;
  if (error) return <Typography>Error: {error}</Typography>;

  const score = data?.securityPostSecPercent || 0;
  const accounts = [
    { name: "Critical", score: data?.securityPostCriticalTotal },
    { name: "High", score: data?.securityPostHighTotal },
    { name: "Medium", score: data?.securityPostModerateTotal },
    { name: "Low", score: data?.securityPostLowTotal },
    // Add more accounts as needed
  ];

  const resourcePassedCount = data?.resourcePassedCount || 0;
  const resourceFailedCount = data?.resourceFailedCount || 0;
  const totalResources = resourcePassedCount + resourceFailedCount;

  const passedPercentage = (resourcePassedCount / totalResources) * 100;
  const failedPercentage = (resourceFailedCount / totalResources) * 100;

  const getColor = (severity) => {
    switch (severity) {
      case "Low":
        return "#4caf50"; // green
      case "Critical":
        return "#7a0709"; // dark red
      case "Medium":
        return "#ff9800"; // orange
      case "High":
        return "#f44336"; // red
      default:
        return "#757575"; // grey for unknown or no severity
    }
  };

  const visibleAccounts = showMore ? accounts : accounts.slice(0, 5);

  const handleGoTo = () => {
    console.log("Go-to action clicked");
    history.push("/Security");
  };

  return (
    <DashboardCard
      title="Security Score"
      actions={[
        {
          label: "go-to",
          icon: (
            <Tooltip title="Open Detailed View">
              <IconButton onClick={handleGoTo} size="small" sx={{ p: 0 }}>
                <OpenInNewOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ),
        },
      ]}
    >
      <Box
        textAlign="center"
        sx={{
          position: "relative",
          width: "100%",
          height: "100px",
          mt: 2,
          mb: -2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <SecurityScoreChart score={score} />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingLeft: "8px" }} // Reduced padding for tighter fit
      >
        <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 0 }}>
          Severity
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          flexGrow: 1,
          pr: 1,
          mb: 1,
          display: "flex", // Flex container to arrange items in rows
          flexWrap: "wrap", // Allow wrapping to the next line
          "& > div": {
            flexBasis: "50%", // Each account takes up 50% of the row (two per row)
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "8px",
            paddingRight: "8px",
            marginTop: "0.5rem", // Reduced margin for tighter spacing
          },
        }}
      >
        {visibleAccounts.map((account, index) => (
          <Box key={index}>
            <Box display="flex" alignItems="center">
              <FiberManualRecordIcon
                sx={{ color: getColor(account.name), fontSize: "10px", mr: 1 }}
              />
              <Typography variant="body2">{account.name}</Typography>
            </Box>
            <Typography variant="body2" sx={{ textAlign: "right" }}>
              {account.score}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: "1rem" }}
      >
        {/* Total Count */}
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              width: "4px", // Reduced width for a more compact look
              height: "45px", // Height that covers both the number and the label
              backgroundColor: "#0073bb", // Blue color for total
              marginRight: "8px", // Reduced margin for tighter spacing
              borderRadius: "2px", // Slightly rounded
            }}
          />
          <Box>
            <Tooltip
              title="Total Resources on which security rules were applied"
              arrow
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", fontSize: "1rem" }} // Reduced font size
              >
                {totalResources}
              </Typography>
            </Tooltip>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", fontSize: "0.65rem" }}
            >
              Total Resources Scanned
            </Typography>
          </Box>
        </Box>

        {/* Failed Count */}
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              width: "4px", // Same reduced width for consistency
              height: "45px", // Same height to cover both number and text
              backgroundColor: "#f44336", // Red color for failed
              marginRight: "8px",
              borderRadius: "2px", // Slightly rounded
            }}
          />
          <Box>
            <Tooltip title="Total resources which failed security rules" arrow>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", fontSize: "1rem" }} // Reduced font size
              >
                {resourceFailedCount}
              </Typography>
            </Tooltip>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", fontSize: "0.65rem" }}
            >
              Resources Failed
            </Typography>
          </Box>
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default SecurityScore;
