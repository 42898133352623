import React, { useState, useEffect } from "react";
import EmbedShell from "../../components/Common/EmbedShell";


const TrendAnalyzer = () => {
  const VIEW_URL = process.env.REACT_APP_COMPLIANCE_TREND_ANALYZER_VIEW;

  return (
    <div style={{ marginTop: "-4rem" }}>
      <EmbedShell viewUrl={VIEW_URL}></EmbedShell>
    </div>
  );
};

export default TrendAnalyzer;
